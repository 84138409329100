define("bottomline-execution/components/scanner-interface", ["exports", "@ember/runloop", "@ember/component", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _runloop, _component, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    epsonScanner: (0, _service.inject)('scanner'),
    hpScanner: (0, _service.inject)(),
    tagName: null,
    areScannerSettingsVisible: false,
    brands: ['hp', 'canon'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('testing')) {
        if (typeof escan !== 'undefined') {
          escan.GetWifiList(function (list) {
            if (list.length > 0) {
              _this.get('appState').set('scanner_ssid', list[0]);
            }

            _this.set('availableScanners', list);
          }, function () {
            _this.set('availableScanners', 'no scanners found');
          });
        } else {
          this.set('availableScanners', 'no scanners found');
        }
      }
    },
    onScanSuccess: function onScanSuccess(imageData) {
      alert("Scanned ".concat(imageData.length, " bytes of data."));
    },
    actions: {
      testScanner: function testScanner() {
        if (this.get('appState.scanner_brand') === 'epson') {
          this.get('epsonScanner').scan((0, _runloop.bind)(this, this.onScanSuccess));
        } else if (this.get('appState.scanner_brand') === 'hp') {
          this.get('hpScanner').betterScanPage(function (res) {
            console.log(res);
          });
        } else if (this.get('appState.scanner_brand') === 'canon') {
          if (typeof cordova === "undefined") {
            alert('Not on mobile device, so assuming test');
          } else {
            cordova.plugins.ScangarooWrapper.scan(function (result) {
              alert(result);
            });
          }
        } else {
          alert('Select brand');
        }
      }
    }
  });

  _exports.default = _default;
});