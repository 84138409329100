define("bottomline-execution/components/user-selection-star", ["exports", "@ember/component", "@ember/object/computed", "bottomline-execution/mixins/app-state"], function (_exports, _component, _computed, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    userSettings: (0, _computed.alias)('appState.userSettings'),
    setting: null,
    iconName: 'star-border',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var isEnabled = this.get('userSettings').get(this.get('setting'));

      this._updateIcon(isEnabled);
    },
    _updateIcon: function _updateIcon(isEnabled) {
      if (isEnabled) {
        this.set('iconName', 'star');
      } else {
        this.set('iconName', 'star-border');
      }
    },
    actions: {
      toggle: function toggle() {
        var isEnabled = this.get('userSettings').toggleProperty(this.get('setting'));

        this._updateIcon(isEnabled);
      }
    }
  });

  _exports.default = _default;
});