define("bottomline-execution/validations/trip-stop-validations", ["exports", "@ember/object/mixin", "@ember/object/computed", "@ember/object", "@ember/utils", "ember-cp-validations"], function (_exports, _mixin, _computed, _object, _utils, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Validations = _exports.ValidationCommonalities = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    DriverRemarks: {
      disabled: (0, _computed.not)('model.isEndInventory'),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, options, model) {
          /* tripStopStorageByTruckInventory returns all trip stop storages that have
             a material that was (or is) also on the truck or trailer */
          var allStoragesEmpty = model.get('tripStopStorageByTruckInventory').isEvery('confirmedQty', 0);
          return allStoragesEmpty || (0, _utils.isPresent)(model.DriverRemarks) || options.message;
        },
        message: 'Driver remarks missing, not all storages are empty',
        dependentKeys: ['model.DriverRemarks', 'model.tripStopStorageByTruckInventory.@each.confirmedQty']
      })]
    },
    ExtOrderID: {
      description: 'Load ticket number for trip stop',
      disabled: (0, _object.computed)(function () {
        var ticketNumberPerTank = this.model.get('truckSettings.load_ticketnb_per_tank');
        var isDelivery = !this.model.get('isLoad');
        var isUnplanned = (0, _utils.isBlank)(this.model.get('plannedQty'));
        return ticketNumberPerTank || isDelivery || isUnplanned;
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    LoadTicketNbPerTankPresent: {
      disabled: (0, _object.computed)(function () {
        var ticketNumberPerTripStop = !this.model.get('truckSettings.load_ticketnb_per_tank');
        var isDelivery = !this.model.get('isLoad');
        var isUnplanned = (0, _utils.isBlank)(this.model.get('plannedQty'));
        return ticketNumberPerTripStop || isDelivery || isUnplanned;
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          // Accept (prefilled) ticket number(s) in (hidden) ExtOrderID field
          if ((0, _utils.isPresent)(model.get('ExtOrderID'))) return true;
          return model.runDeliveredTankValidation('hasTicketNumberOnConfirmedQty');
        },
        dependentKeys: ['model.ExtOrderID', 'model.trip_stop_storages.@each.confirmedQty', 'model.trip_stop_storages.@each.TicketNb']
      })]
    },
    Odometer: {
      description: 'Odometer',
      disabled: (0, _object.computed)(function () {
        var truckSettings = this.model.get('truckSettings');
        var requiredForLoad = this.model.get('isLoad') && truckSettings.get('odometer_on_load') == 'mandatory';
        var requiredForUnload = this.model.get('isUnload') && truckSettings.get('odometer_on_unload') == 'mandatory';
        var requiredForEndInventory = this.model.get('isEndInventory') && truckSettings.get('odometer_on_end_inventory') == 'mandatory';
        return !requiredForLoad && !requiredForUnload && !requiredForEndInventory;
      }),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        positive: true
      })]
    },
    PicturePresent: {
      disabled: (0, _object.computed)(function () {
        var truckSettings = this.model.get('truckSettings');
        var requiredForLoad = this.model.get('isLoad') && truckSettings.get('on_load_mandatory_picture');
        var requiredForDelivery = this.model.get('isUnload') && truckSettings.get('on_delivery_mandatory_picture');
        var requiredForEndInventory = this.model.get('isEndInventory') && truckSettings.get('on_end_inventory_mandatory_picture');
        return !requiredForLoad && !requiredForDelivery && !requiredForEndInventory;
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, options, model) {
          return model.get('hasPictures') || options.message;
        },
        message: (0, _object.computed)(function () {
          return "Picture missing for trip stop type ".concat(this.model.get('ActivityCode'));
        }),
        dependentKeys: ['model.hasPictures']
      })]
    },
    PostDipsOnUnloadPresent: {
      disabled: (0, _object.computed)(function () {
        var notDelivery = !this.model.get('isUnload');
        var postDipNotRequired = this.model.get('truckSettings.postdip_enabled') !== 'required';
        return notDelivery || postDipNotRequired;
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          return model.runDeliveredTankValidation('endInventoryQty');
        },
        dependentKeys: ['model.trip_stop_storages.@each.endInventoryQty', 'model.trip_stop_storages.@each.confirmedQty']
      })]
    },
    PostDipsOnUnloadValid: {
      disabled: (0, _object.computed)(function () {
        var notDelivery = !this.model.get('isUnload');
        var postDipValidationNotRequired = !this.model.get('truckSettings.postdip_validation_enabled');
        return notDelivery || postDipValidationNotRequired;
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          var deliveredTanksEndInventoryValidations = model.get('confirmedTripStopStorages').mapBy('validations.attrs.endInventoryQtyValid');
          var endInventoriesValid = deliveredTanksEndInventoryValidations.isEvery('isValid', true);
          return endInventoriesValid || model.composeMessage(deliveredTanksEndInventoryValidations);
        },
        isWarning: true,
        dependentKeys: ['model.trip_stop_storages.@each.confirmedQty', 'model.trip_stop_storages.@each.endInventoryQty']
      })]
    },
    PostUllageOnUnloadPresent: {
      disabled: (0, _computed.not)('model.verifyPostUllage'),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          return model.runDeliveredTankValidation('postDipUllage');
        },
        dependentKeys: ['model.trip_stop_storages.@each.confirmedQty', 'model.trip_stop_storages.@each.postDipUllage']
      })]
    },
    PostUllageOnUnloadValid: {
      disabled: (0, _computed.not)('model.verifyPostUllage'),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          return model.runDeliveredTankValidation('postDipUllageValid');
        },
        dependentKeys: ['model.trip_stop_storages.@each.confirmedQty', 'model.trip_stop_storages.@each.postDipUllage']
      })]
    }
  });
  _exports.Validations = Validations;

  var ValidationCommonalities = _mixin.default.create({
    composeMessage: function composeMessage(validations) {
      return validations.filterBy('isInvalid').mapBy('message').uniq().join(',');
    },
    runDeliveredTankValidation: function runDeliveredTankValidation(attributeName) {
      var deliveredTankValidations = this.get('confirmedTripStopStorages').mapBy("validations.attrs.".concat(attributeName));
      var deliveredTanksValid = deliveredTankValidations.isEvery('isValid', true);
      return deliveredTanksValid || 'Invalid trip stop storage(s): ' + this.composeMessage(deliveredTankValidations);
    },
    verifyPostUllage: (0, _object.computed)('isUnload', 'truckSettings.postdip_enabled', function () {
      var isDelivery = this.get('isUnload');
      var postDipSetting = this.get('truckSettings.postdip_enabled');
      var postUllageRequired = postDipSetting === 'required_by_ullage';
      return isDelivery && postUllageRequired;
    })
  });

  _exports.ValidationCommonalities = ValidationCommonalities;
});