define("bottomline-execution/serializers/trip-stop-authorization", ["exports", "active-model-adapter", "@ember/object/computed", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _activeModelAdapter, _computed, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_appState.default, {
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    persistPredip: (0, _object.computed)(function () {
      return ['on_pre_auth', 'on_pre_auth_by_ullage'].includes(this.get('truckSettings.predip_enabled'));
    }),
    serialize: function serialize(snapshot, _options) {
      var postDip = snapshot.attr('phase') === 'postAuthorization';
      if (postDip || !this.get('persistPredip')) return null;
      var tripStopStorages = snapshot.safeHasMany('tripStopStorages');
      var predipData = tripStopStorages.map(function (tss) {
        return tss.serialize({
          purpose: 'persistPredip'
        });
      });
      return {
        pre_dips: predipData
      };
    }
  });

  _exports.default = _default;
});