define("bottomline-execution/templates/components/details-vehicle-load", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s2pRaFkL",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"compartments\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],null,[[\"showStandardLiters\",\"truckStorage\",\"trip-stop\",\"parentController\",\"showAllCompartments\",\"tripStopStorage\"],[[30,0,[\"showStandardLiters\"]],[30,1],[30,0,[\"trip-stop\"]],[30,0,[\"parentController\"]],[30,0,[\"showAllCompartments\"]],[30,0,[\"tripStopStorage\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"compartment\"],false,[\"each\",\"-track-array\",\"trip-stop-truck-storage-load\"]]",
    "moduleName": "bottomline-execution/templates/components/details-vehicle-load.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});