define("bottomline-execution/controllers/truck-settings", ["exports", "@ember/object/computed", "@ember/object", "@ember/controller", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _computed, _object, _controller, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, {
    router: (0, _service.inject)(),
    online: (0, _computed.alias)('appState.online'),
    sortedTruckSettings: (0, _object.computed)('appState.truckSettings', function () {
      var truckSettings = this.get('appState.truckSettings');
      var sortedTruckSettingsNames = truckSettings.get('settingNames').sort();
      return sortedTruckSettingsNames.map(function (name) {
        return {
          label: name,
          value: truckSettings[name]
        };
      });
    }),
    actions: {
      return: function _return() {
        this.get('online') && this.router.transitionTo('shifts.index');
      }
    }
  });

  _exports.default = _default;
});