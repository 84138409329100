define("bottomline-execution/serializers/bx/trip-stop-storage", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service", "ember-uuid", "bottomline-execution/serializers/trip-stop-storage"], function (_exports, _computed, _object, _utils, _service, _emberUuid, _tripStopStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStopStorage.default.extend({
    store: (0, _service.inject)(),
    tripStopJson: {},
    isLoadStop: (0, _computed.equal)('tripStopJson.type', 'load'),
    actionFilterKey: (0, _object.computed)('isLoadStop', function () {
      return this.get('isLoadStop') ? 'product_id' : 'site_storage_id';
    }),
    storageFilterKey: (0, _object.computed)('isLoadStop', function () {
      return this.get('isLoadStop') ? 'MaterialID' : 'id';
    }),
    plannedActions: [],
    executedActions: [],
    mapResponse: function mapResponse(tripStopJson, plannedActions, executedActions) {
      var _this = this;

      this.set('tripStopJson', tripStopJson);
      this.set('plannedActions', plannedActions);
      this.set('executedActions', executedActions);
      var storages = this.get('store').peekAll('storage').filterBy('SiteID', tripStopJson.location.id);
      var tripStopStorages = storages.map(function (storage) {
        var planned = _this._filterActions('plannedActions', storage);

        var executed = _this._filterActions('executedActions', storage);

        var tripStopStorageId = tripStopJson.id + '_' + storage.id;
        var driverReportedInventories = (tripStopJson.driver_reported_inventories || []).filter(function (driverReportedInventory) {
          return driverReportedInventory.storage_id == storage.id;
        });

        var storageDips = _this._createStorageDips(tripStopStorageId, driverReportedInventories);

        return {
          id: tripStopStorageId,
          type: 'trip-stop-storage',
          attributes: {
            confirmedQty: _this._calculateTotal(executed, 'volume_in_ambient_liters'),
            confirmed15Qty: _this._calculateTotal(executed, 'volume_in_standard_liters'),
            Density: _this._calculateAverage(executed, 'density'),
            DropCount: _this._dropCount(tripStopJson, storage),
            endInventoryQty: _this._storageDipValue(storageDips, 'endInventoryQty'),
            MaterialID: storage.MaterialID,
            plannedQty: _this._calculateTotal(planned, 'volume_in_ambient_liters'),
            PostDeliveryPct: _this._storageDipValue(storageDips, 'PostDeliveryPct'),
            PreDeliveryPct: _this._storageDipValue(storageDips, 'PreDeliveryPct'),
            startInventoryQty: _this._storageDipValue(storageDips, 'startInventoryQty'),
            StorageID: storage.id,
            TankFilledYN: _this._tankFilled(tripStopJson, storage),
            Temperature: _this._calculateAverage(executed, 'temperature') || undefined,
            TripStopID: tripStopJson.id
          },
          relationships: {
            storage: {
              data: {
                type: 'storage',
                id: storage.id
              }
            }
          }
        };
      });
      return {
        data: tripStopStorages
      };
    },
    _filterActions: function _filterActions(actionsType, storage) {
      var targetValue = storage.get(this.get('storageFilterKey'));
      return this.get(actionsType).filterBy(this.get('actionFilterKey'), targetValue);
    },
    _calculateTotal: function _calculateTotal(actions, attribute) {
      if ((0, _utils.isEmpty)(actions)) return null;
      return actions.reduce(function (sum, action) {
        return sum + Number(action[attribute]);
      }, 0);
    },
    _calculateAverage: function _calculateAverage(actions, attribute) {
      var actionsWithValue = actions.filterBy(attribute);
      if ((0, _utils.isEmpty)(actionsWithValue)) return null;

      var total = this._calculateTotal(actionsWithValue, attribute);

      var average = total / actionsWithValue.length;
      return Math.round((average + Number.EPSILON) * 100) / 100;
    },
    _dropCount: function _dropCount(tripStopJson, storage) {
      var dropCount = this._customField(tripStopJson, storage, 'drop_count');

      if ((0, _utils.isPresent)(dropCount)) return parseInt(dropCount.value);
    },
    _tankFilled: function _tankFilled(tripStopJson, storage) {
      var filledUp = this._customField(tripStopJson, storage, 'filled_up');

      if ((0, _utils.isPresent)(filledUp)) return filledUp.value == 'true';
    },
    _customField: function _customField(tripStopJson, storage, fieldName) {
      if ((0, _utils.isEmpty)(tripStopJson.custom_fields)) return null;
      return tripStopJson.custom_fields.filterBy('target_id', storage.get('id')).findBy('name', fieldName);
    },
    storageDipTypes: [{
      attribute: "startInventoryQty",
      relativeTiming: "PRE_DELIVERY",
      valueType: "QUANTITY"
    }, {
      attribute: "endInventoryQty",
      relativeTiming: "POST_DELIVERY",
      valueType: "QUANTITY"
    }, {
      attribute: "PreDeliveryPct",
      relativeTiming: "PRE_DELIVERY",
      valueType: "PERCENTAGE"
    }, {
      attribute: "PostDeliveryPct",
      relativeTiming: "POST_DELIVERY",
      valueType: "PERCENTAGE"
    }],
    _createStorageDips: function _createStorageDips(tripStopStorageId, driverReportedInventories) {
      var _this2 = this;

      return driverReportedInventories.map(function (driverReportedInventory) {
        var storageDipType = _this2.get('storageDipTypes').find(function (storageDipType) {
          return storageDipType.relativeTiming + '_' + storageDipType.valueType == driverReportedInventory.type;
        });

        var storageDip = _this2.get('store').peekRecord('storage-dip-id', driverReportedInventory.id) || _this2.get('store').createRecord('storage-dip-id', {
          id: driverReportedInventory.id
        });

        storageDip.setProperties({
          tripStopStorageId: tripStopStorageId,
          attribute: storageDipType.attribute,
          value: driverReportedInventory.value
        });
        return storageDip;
      });
    },
    _storageDipValue: function _storageDipValue(storageDips, attribute) {
      return (storageDips.findBy('attribute', attribute) || {}).value;
    },
    serializeStorageDips: function serializeStorageDips(snapshot) {
      var _this3 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.get('storageDipTypes').map(function (dipType) {
        var value = snapshot.attr(dipType.attribute);
        if ((0, _utils.isEmpty)(value)) return null;
        return {
          id: _this3._storageDipId(snapshot.id, dipType.attribute).get('id'),
          measured_at: options[dipType.relativeTiming.toLowerCase()],
          site_storage_id: snapshot.attr('StorageID'),
          value: value,
          value_type: dipType.valueType,
          relative_timing: dipType.relativeTiming
        };
      }).compact();
    },
    _storageDipId: function _storageDipId(tripStopStorageId, attribute) {
      var storageDip = this.get('store').peekAll('storage-dip-id').find(function (dip) {
        return dip.tripStopStorageId == tripStopStorageId && dip.attribute == attribute;
      });
      return storageDip || this.get('store').createRecord('storage-dip-id', {
        id: (0, _emberUuid.v4)(),
        tripStopStorageId: tripStopStorageId,
        attribute: attribute
      });
    }
  });

  _exports.default = _default;
});