define("bottomline-execution/routes/login", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),
    router: (0, _service.inject)(),
    routeIfAlreadyAuthenticated: 'index',
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.get('router').transitionTo(this.get('routeIfAlreadyAuthenticated'));
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});