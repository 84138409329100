define("bottomline-execution/routes/dashboard", ["exports", "@ember/object/computed", "@ember/routing/route", "@ember/service", "@ember/utils", "bottomline-execution/mixins/app-state"], function (_exports, _computed, _route, _service, _utils, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_appState.default, {
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    online: (0, _computed.alias)('appState.online'),
    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) return;
      var canOpenShifts = (0, _utils.isPresent)(this.get('appState.currentTruck')) && this.get('online');

      if (canOpenShifts) {
        return this.get('router').transitionTo('shifts.index');
      }

      return this.get('router').transitionTo('edit-truck');
    }
  });

  _exports.default = _default;
});