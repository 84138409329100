define("bottomline-execution/models/bx/end-inventory", ["exports", "bottomline-execution/models/bx/virtual-trip-stop", "bottomline-execution/mixins/adapter-options"], function (_exports, _virtualTripStop, _adapterOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */
  var _default = _virtualTripStop.default.extend(_adapterOptions.default, {
    start: function start() {
      this.get('trip').correctionStop().save();
      this.save();
    },
    _persistChanges: function _persistChanges() {
      var _this = this;

      return this.save(this.adapterAction('confirm-end-inventory')).catch(function (error) {
        _this._handleFatalSaveError(error);
      });
    }
  });

  _exports.default = _default;
});