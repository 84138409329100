define("bottomline-execution/adapters/toggle", ["exports", "ember-localstorage-adapter/adapters/ls-adapter"], function (_exports, _lsAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _lsAdapter.default.extend({
    namespace: 'toggles',
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return true;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    }
  });

  _exports.default = _default;
});