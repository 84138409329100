define("bottomline-execution/mixins/raw-truck-settings", ["exports", "@ember/object/mixin", "@ember/object", "bottomline-execution/mixins/truck-settings-id", "bottomline-execution/mixins/raw-app-state"], function (_exports, _mixin, _object, _truckSettingsId, _rawAppState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_truckSettingsId.default, _rawAppState.default, {
    get useCompartments() {
      return this._truckSettings().compartments === true;
    },

    _truckSettings: function _truckSettings() {
      if (localStorage['truck-setting'] && localStorage.state) {
        var truckSettingsRecords = JSON.parse(localStorage['truck-setting'])['truck-setting'].records;
        var currentTruckId = this.truckSettingsId(this._appState().currentTruck);
        return truckSettingsRecords[currentTruckId] || new _object.default();
      } else {
        return new _object.default();
      }
    }
  });

  _exports.default = _default;
});