define("bottomline-execution/utils/sort-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alphaNumericSort = alphaNumericSort;
  _exports.sortCollection = sortCollection;

  function alphaNumericSort(a, b) {
    return a.localeCompare(b, undefined, {
      numeric: true
    });
  }

  function sortCollection(collection, sortByAttribute) {
    var order = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      reverse: false
    };
    var sortedCollection = collection.sort(function (a, b) {
      return alphaNumericSort(a.get(sortByAttribute), b.get(sortByAttribute));
    });
    return order.reverse ? sortedCollection.reverse() : sortedCollection;
  }
});