define("bottomline-execution/routes/truck-settings", ["exports", "@ember/routing/route", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _route, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_appState.default, {
    ajax: (0, _service.inject)(),
    truckSettingsAjax: (0, _service.inject)(),
    model: function model() {
      return this.get('appState.truckSettings');
    }
  });

  _exports.default = _default;
});