define("bottomline-execution/templates/components/geographical-maps/navigation-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WCQlXdC1",
    "block": "[[[41,[30,0,[\"isFullscreen\"]],[[[6,[39,1],null,[[\"to\"],[\"navigation-map-fullscreen-wormhole\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"navigation-map__map-container map__container--fullscreen\"],[14,1,\"geographical-map-fullscreen\"],[12],[13],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"  \"],[10,0],[14,0,\"navigation-map\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"navigation-map__map-container\"],[14,1,\"geographical-map\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"ember-wormhole\"]]",
    "moduleName": "bottomline-execution/templates/components/geographical-maps/navigation-map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});