define("bottomline-execution/templates/components/user-selection-star", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4CRgXQA+",
    "block": "[[[1,[28,[35,0],null,[[\"actionOnClick\",\"isEnabled\",\"iconName\",\"iconSize\"],[[28,[37,1],[[30,0],\"toggle\"],null],true,[30,0,[\"iconName\"]],\"lg\"]]]],[1,\"\\n\"]],[],false,[\"bl-components/icon-button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/user-selection-star.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});