define("bottomline-execution/services/truck-selector", ["exports", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_appState.default, {
    customerConfiguration: (0, _service.inject)(),
    select: function select(truck) {
      return this._setCurrentTruck(truck.get('id'));
    },
    resetCurrentTruck: function resetCurrentTruck() {
      return this._setCurrentTruck(null);
    },
    _setCurrentTruck: function _setCurrentTruck(truckId) {
      var appState = this.get('appState');
      appState.setProperties({
        'currentTruck': truckId,
        'currentTrailer': null,
        'selectedShiftId': null,
        'selectedTripId': null,
        'selectedTripStopId': null
      });
      appState.save();
      return this._updateTabletConfiguration(truckId);
    },
    _updateTabletConfiguration: function _updateTabletConfiguration(truckId) {
      return this.get('customerConfiguration').updateTabletConfiguration(truckId);
    }
  });

  _exports.default = _default;
});