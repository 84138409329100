define("bottomline-execution/components/return-materials", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      selectReturnMaterialTripStopStorage: function selectReturnMaterialTripStopStorage(id) {
        this.get('selectReturnMaterialTripStopStorage')(id);
      }
    }
  });

  _exports.default = _default;
});