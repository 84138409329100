define("bottomline-execution/components/reorder-stops-in-trip", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _component, _computed, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    trip_stop_reordering_enabled: (0, _computed.alias)('truckSettings.trip_stop_reordering_enabled'),
    tripStops: [],
    indexedTripStops: (0, _object.computed)('tripStops.@each', function () {
      return this.get('tripStops').toArray();
    }),
    firstStop: (0, _object.computed)('tripStops.@each', function () {
      return this.get('tripStops').filterBy('variableExecutionOrder').get('firstObject');
    }),
    lastStop: (0, _object.computed)('tripStops.@each', function () {
      return this.get('tripStops').filterBy('variableExecutionOrder').get('lastObject');
    }),
    swapPositions: function swapPositions(stopA, stopB) {
      var newSequenceNb = stopA.get('SequenceNb');
      stopA.set('SequenceNb', stopB.get('SequenceNb'));
      stopB.set('SequenceNb', newSequenceNb);
    },
    actions: {
      moveDown: function moveDown(tripStop) {
        var position = this.get('indexedTripStops').indexOf(tripStop);
        var laterTripStop = this.get('indexedTripStops')[position + 1];
        this.swapPositions(tripStop, laterTripStop);
      },
      moveUp: function moveUp(tripStop) {
        var position = this.get('indexedTripStops').indexOf(tripStop);
        var earlierTripStop = this.get('indexedTripStops')[position - 1];
        this.swapPositions(tripStop, earlierTripStop);
      },
      selectTripStop: function selectTripStop(tripStop) {
        this.get('selectTripStop')(tripStop);
      },
      toNextTrip: function toNextTrip(tripStop) {
        this.get('toNextTrip')(tripStop);
      },
      toPreviousTrip: function toPreviousTrip(tripStop) {
        this.get('toPreviousTrip')(tripStop);
      }
    }
  });

  _exports.default = _default;
});