define("bottomline-execution/components/details-vehicle-load", ["exports", "@ember/object/computed", "@ember/component", "bottomline-execution/mixins/app-state"], function (_exports, _computed, _component, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    classNames: 'storage-compartments',
    tagName: 'div',
    truckSettings: (0, _computed.alias)('appState.truckSettings')
  });

  _exports.default = _default;
});