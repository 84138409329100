define("bottomline-execution/components/atg-information", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/storages-loader"], function (_exports, _computed, _component, _object, _runloop, _service, _appState, _storagesLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _component.default.extend(_appState.default, _storagesLoader.default, {
    store: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    language: (0, _computed.alias)('appState.language'),
    online: (0, _computed.alias)('appState.online'),
    tripStopStorages: [],
    tripStopStoragesWithAtgData: (0, _object.computed)('tripStopStorages.[]', function () {
      return this.get('tripStopStorages').filterBy('storage');
    }),
    allShownTanks: (0, _object.computed)('tripStopStoragesWithAtgData.[]', function () {
      return this.get('tripStopStoragesWithAtgData').mapBy('storage');
    }),
    areDetailsShown: false,
    isAtgInfoLoading: false,
    detailsButtonTranslationKey: (0, _object.computed)('areDetailsShown', function () {
      if (this.get('areDetailsShown')) return 'problem-atg-sites.hideDetails';
      return 'problem-atg-sites.showDetails';
    }),
    oldestRetrievalTime: (0, _object.computed)('allShownTanks.@each.lastTimeRetrieved', function () {
      var lastRetrievalTimes = this.get('allShownTanks').mapBy('lastTimeRetrieved');
      return Math.min.apply(Math, _toConsumableArray(lastRetrievalTimes.uniq()));
    }),
    lastUpdated: (0, _object.computed)('oldestRetrievalTime', function () {
      var oldestRetrievalTimeInMilliseconds = this.get('oldestRetrievalTime') * 1000;
      if (oldestRetrievalTimeInMilliseconds <= 0) return this.get('i18n').t('last_updated.unknown');
      return moment(oldestRetrievalTimeInMilliseconds).locale(this.get('language')).fromNow();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('online')) {
        this.updateAtgInformation();
        this.startLastUpdatedIndicatorRefresh();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.endLastUpdatedIndicatorRefresh();
    },
    startLastUpdatedIndicatorRefresh: function startLastUpdatedIndicatorRefresh() {
      this.set('refreshLastUpdatedIndicatorAllowed', true);
      this.refreshLastUpdatedIndicator();
    },
    refreshLastUpdatedIndicator: function refreshLastUpdatedIndicator() {
      (0, _runloop.later)(this, function () {
        this.notifyPropertyChange('oldestRetrievalTime');

        if (this.get('refreshLastUpdatedIndicatorAllowed')) {
          this.refreshLastUpdatedIndicator();
        }
      }, 60 * 1000);
    },
    endLastUpdatedIndicatorRefresh: function endLastUpdatedIndicatorRefresh() {
      this.set('refreshLastUpdatedIndicatorAllowed', false);
    },
    updateAtgInformation: function updateAtgInformation() {
      var _this = this;

      this.set('isAtgInfoLoading', true);

      this._loadRecentInventories(this.get('allShownTanks')).finally(function () {
        if (!_this.isDestroyed) {
          _this.set('isAtgInfoLoading', false);
        }
      });
    },
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });

  _exports.default = _default;
});