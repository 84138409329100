define("bottomline-execution/serializers/bx/load-report-transaction", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    mapResponse: function mapResponse(loadReportId, loadReportTransactions) {
      var mappedLoadReportTransactions = loadReportTransactions.map(function (json) {
        return {
          id: loadReportId + '_' + json.compartment_number,
          type: 'load-report-transaction',
          attributes: {
            confirmedQty: json.ambient_quantity,
            confirmed15Qty: json.standard_quantity,
            Density: json.density,
            MaterialID: json.product_id,
            Temperature: json.temperature,
            TruckID: json.vehicle_id,
            TruckStorageID: json.compartment_number
          }
        };
      });
      return {
        data: mappedLoadReportTransactions
      };
    }
  });

  _exports.default = _default;
});