define("bottomline-execution/controllers/trip-stops/scan", ["exports", "@ember/array", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "bottomline-execution/config/environment", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/transition-to-trip-stop"], function (_exports, _array, _controller, _object, _service, _utils, _environment, _appState, _transitionToTripStop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _transitionToTripStop.default, {
    scanner: (0, _service.inject)(),
    hpScanner: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    pictureSaver: (0, _service.inject)(),
    router: (0, _service.inject)(),
    queryParams: ['relatedStorageId'],
    loading: false,
    scanners: (0, _array.A)([]),
    previewImage: null,
    isAtx: (0, _object.computed)(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),
    testImage: function testImage() {
      return 'R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    },
    getFileEntry: function getFileEntry(imageURI) {
      var _this = this;

      window.resolveLocalFileSystemURL(imageURI, function (fileEntry) {
        var reader = new FileReader();

        reader.onloadend = function (e) {
          var image = e.target.result.split('base64,')[1];

          if (!(0, _utils.isEmpty)(image)) {
            _this.set('previewImage', image);
          } else {
            _this.get('toast').error(_this.get('i18n').t('no_image_data.scan_file'));
          }

          fileEntry.remove();
        };

        fileEntry.file(function (file) {
          return reader.readAsDataURL(file);
        });
      });
    },
    actions: {
      scan: function scan() {
        var _this2 = this;

        console.log("Start scanning with: ".concat(this.get('appState.scanner_brand')));
        this.get('appState').save();

        if (this.get('appState.scanner_brand') === 'epson') {
          this.get('scanner').scan(function (imageData) {
            _this2.set('previewImage', imageData);

            _this2.connectToOriginalNetwork();

            console.log('Scan finished');
          });
        } else if (this.get('appState.scanner_brand') === 'hp') {
          this.get('hpScanner').betterScanPage(function (result) {
            return _this2.getFileEntry(result[0]);
          });
        } else if (this.get('appState.scanner_brand') === 'canon') {
          if (typeof cordova === "undefined") {
            alert('No mobile device bindings found, so assuming test otherwise rebuild');
          } else {
            cordova.plugins.ScangarooWrapper.scan(function () {
              _this2.get('toast').info('Scan cancelled...');

              _this2.router.transitionTo('trip-stops.show', _this2.get('model').id);

              console.error('SCANNER', 'Cancelled');
            }, function (result) {
              console.info('SCANNER', 'Received: ' + result);
              var cordovaFriendlyLocation = result.replace("file:///mnt/sdcard", "file:///storage/extsd");
              console.info('SCANNER File converted to: ' + cordovaFriendlyLocation);

              _this2.getFileEntry(cordovaFriendlyLocation);
            });
          }
        } else {
          alert('Select brand');
        }
      },
      confirm: function confirm() {
        if ((0, _utils.isEmpty)(this.get('previewImage'))) {
          this.get('toast').error(this.get('i18n').t('no_image_data.scan_confirm'));
          return false;
        }

        var tripStop = this.get('model');
        var scannedPicture = tripStop.get('pictures').createRecord({
          imageData: this.get('previewImage'),
          TableKey: this.get('model').id,
          TableName: 'TripStop',
          Description: 'png',
          StorageID: this.get('relatedStorageId') || -1,
          PictureType: 'scan',
          BE_PictureGUID: this.get('model').createGuid()
        });
        this.get('pictureSaver').save(scannedPicture);
        this.set('previewImage', null);

        this._transitionToTripStop(tripStop);
      },
      refresh: function refresh() {
        var _this3 = this;

        if (!escan) {
          WifiWizard.startScan(function () {
            WifiWizard.getScanResults(function (list) {
              _this3.set('scanners', list.filter(function (e) {
                return e.SSID.indexOf('DIRECT-') > -1;
              }));
            });
          });
        }
      },
      cancel: function cancel() {
        this.set('previewImage', null);
        var tripStop = this.get('model');

        this._transitionToTripStop(tripStop);
      }
    }
  });

  _exports.default = _default;
});