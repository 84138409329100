define("bottomline-execution/components/toggle-show", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember/service", "@ember/utils"], function (_exports, _component, _object, _string, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    incrementMediator: 0,
    title: '',
    translate: true,
    classNames: ['card'],
    padContent: true,
    toggle: (0, _object.computed)(function () {
      var store = this.get('store');
      var toggleName = this.get('toggleName');
      var toggle = store.peekRecord('toggle', toggleName);

      if ((0, _utils.isBlank)(toggle)) {
        toggle = store.createRecord('toggle', {
          id: toggleName
        });
      }

      return toggle;
    }),
    toggleName: (0, _object.computed)(function () {
      var toggleId = (0, _string.classify)(this.get('title'));
      return "show".concat(toggleId);
    }),
    showContent: (0, _object.computed)('toggleState', {
      get: function get() {
        return this.get('toggle.show');
      },
      set: function set(_key, value) {
        var toggle = this.get('toggle');
        toggle.set('show', value);
        toggle.save();
        return value;
      }
    }),
    actions: {
      toggle: function toggle() {
        var toggle = this.get('toggle');
        toggle.toggleProperty('show');
        toggle.save();
        this.notifyPropertyChange('toggleState');
      }
    }
  });

  _exports.default = _default;
});