define("bottomline-execution/routes/trip-stops/new/from-order", ["exports", "@ember/routing/route", "rsvp", "bottomline-execution/mixins/trip-stop-material-on-truck"], function (_exports, _route, _rsvp, _tripStopMaterialOnTruck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_tripStopMaterialOnTruck.default, {
    model: function model() {
      var trip = this.modelFor('trips.show');
      var tripId = trip.id;
      var materialsOnTruck = this.tripStopMaterialOnTruck();
      return _rsvp.default.hash({
        orderDetails: this.get('store').query('order-detail', {
          trip_id: tripId
        }),
        materialsOnTruck: materialsOnTruck,
        tripId: tripId
      });
    }
  });

  _exports.default = _default;
});