define("bottomline-execution/utils/storage-id-for-material-id-util", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.storageIdForMaterialIDUtil = storageIdForMaterialIDUtil;

  function storageIdForMaterialIDUtil(id, storages) {
    var storageId;
    var storage = storages.findBy('MaterialID', id);

    if ((0, _utils.isPresent)(storage)) {
      storageId = Number(storage.id) || storage.id;
    }

    return storageId;
  }
});