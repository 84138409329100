define("bottomline-execution/models/trailer", ["exports", "ember-data/model", "ember-data", "bottomline-execution/mixins/vehicle-identifier"], function (_exports, _model, _emberData, _vehicleIdentifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = _model.default.extend(_vehicleIdentifier.default, {
    TruckID: attr('string'),
    License: attr('string'),
    MeterTypeID: attr('string'),
    VehicleType: attr('number'),
    displayName: attr('string'),
    truck_inventories: hasMany('truck-inventory')
  });

  _exports.default = _default;
});