define("bottomline-execution/templates/components/report-viewer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Spd/i+Jf",
    "block": "[[[41,[30,0,[\"iconName\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"isEnabled\",\"iconName\",\"iconSize\",\"additionalCssClasses\",\"debugLabel\"],[[28,[37,2],[[30,0],[30,0,[\"generate\"]]],null],[30,0,[\"isEnabled\"]],[30,0,[\"iconName\"]],[30,0,[\"iconSize\"]],[30,0,[\"buttonClassNames\"]],\"scanner_interface_scan_icon_button\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[6,[39,3],null,[[\"actionOnClick\",\"isEnabled\",\"additionalCssClasses\",\"debugLabel\"],[[28,[37,2],[[30,0],[30,0,[\"generate\"]]],null],[30,0,[\"isEnabled\"]],[30,0,[\"buttonClassNames\"]],\"scanner_interface_scan_button\"]],[[\"default\"],[[[[18,1,null]],[]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"bl-components/icon-button\",\"action\",\"bl-components/button\",\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/report-viewer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});