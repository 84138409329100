define("bottomline-execution/adapters/bx/truck", ["exports", "bottomline-execution/adapters/bx/base-adapter", "rsvp", "@ember/object", "@ember/service"], function (_exports, _baseAdapter, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend({
    session: (0, _service.inject)(),
    driverId: (0, _object.computed)('session.session.authenticated.driver_id', function () {
      return this.get('session.session.authenticated.driver_id');
    }),
    findAll: function findAll() {
      var _this = this;

      return new _rsvp.Promise(function (resolve, reject) {
        var ajax = _this.get('ajaxService');

        var truckService = _this.get('truckService');

        var getAvailableTrucks = ajax.request("".concat(truckService, "/trucks?driver=").concat(_this.get('driverId')));

        var createVehiclesList = function createVehiclesList(vehicleIds) {
          return ajax.post("".concat(truckService, "/vehicles/list"), {
            data: vehicleIds
          });
        };

        var getVehicleList = function getVehicleList(uuid) {
          return ajax.request("".concat(truckService, "/vehicles/list/").concat(uuid));
        };

        return getAvailableTrucks.then(function (compoundVehicles) {
          var drivableVehicleIds = compoundVehicles.map(function (compound) {
            return compound.drivable_vehicle_id;
          });
          var trailerIds = compoundVehicles.map(function (compound) {
            return compound.trailers;
          }).flat();
          var vehicleIds = [].concat(drivableVehicleIds, trailerIds);
          return createVehiclesList(vehicleIds).then(function (listId) {
            return getVehicleList(listId).then(function (vehicles) {
              resolve({
                compoundVehicles: compoundVehicles,
                vehicles: vehicles
              });
            }).catch(function (error) {
              reject(error);
            });
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          var errorMessage = error.message;
          console.log('Error retrieving trucks: ' + errorMessage);
          reject(errorMessage);
        });
      });
    }
  });

  _exports.default = _default;
});