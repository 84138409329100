define("bottomline-execution/models/bx/trip-stop-truck-storage", ["exports", "bottomline-execution/models/trip-stop-truck-storage", "@ember/object", "@ember/utils", "ember-cp-validations", "@ember/service", "ember-data", "bottomline-execution/mixins/trip-stop-type"], function (_exports, _tripStopTruckStorage, _object, _utils, _emberCpValidations, _service, _emberData, _tripStopType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    MaterialID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    StorageID: {
      disabled: (0, _object.computed)(function () {
        return !this.model.get('trip_stop.isUnload');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, options, model) {
          var siteId = model.get('trip_stop.site.id');
          var siteStorageIds = model.get('store').peekAll('storage').filterBy('SiteID', siteId).mapBy('id');
          return siteStorageIds.includes(model.get('StorageID')) || options.message;
        },
        message: (0, _object.computed)(function () {
          var site = this.model.get('trip_stop.site');
          return JSON.stringify({
            error: 'UNKNOWN_SITE_STORAGE',
            compartment: this.model.get('TruckStorageID'),
            site: site.id,
            storage: this.model.get('StorageID')
          });
        }),
        dependentKeys: ['model.StorageID']
      })]
    },
    TruckID: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, options, model) {
          var shift = model.get('trip_stop.trip.shift');
          var compoundVehicle = model.get('store').peekRecord('compound-vehicle', shift.get('CompoundVehicleID'));
          return compoundVehicle.get('vehicleIds').includes(model.get('TruckID')) || options.message;
        },
        message: (0, _object.computed)(function () {
          var shift = this.model.get('trip_stop.trip.shift');
          return JSON.stringify({
            error: 'UNKNOWN_VEHICLE',
            compartment: this.model.get('TruckStorageID'),
            shift: shift.id,
            vehicle: this.model.get('TruckID')
          });
        }),
        dependentKeys: ['model.TruckID']
      })]
    },
    TruckStorageID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    Volume: {
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          return (0, _utils.isPresent)(model.get('confirmedQty')) || (0, _utils.isPresent)(model.get('confirmed15Qty'));
        },
        dependentKeys: ['model.confirmedQty', 'model.confirmed15Qty']
      })]
    }
  });

  var _default = _tripStopTruckStorage.default.extend(Validations, _tripStopType.default, {
    store: (0, _service.inject)(),
    ManualOrderID: attr('string'),
    isVolatileRemoveableRecord: (0, _object.computed)('isLocalRecord', function () {
      if (this.get('trip_stop.isStartInventory')) {
        return false;
      } else {
        return this._super.apply(this, arguments);
      }
    }),
    trip_stop: (0, _object.computed)('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.get('store').peekRecord(this.tripStopType(), this.get('TripStopID'));
      }
    }),
    linkTripStopStorage: function linkTripStopStorage(tripStopStorage) {
      this.setProperties({
        StorageID: tripStopStorage.get('StorageID'),
        TripStopStorageID: tripStopStorage.get('id')
      });

      if (this.get('isLoad')) {
        this.set('MaterialID', tripStopStorage.get('MaterialID'));
      }
    }
  });

  _exports.default = _default;
});