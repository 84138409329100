define("bottomline-execution/models/geographical-map/tank-on-map", ["exports", "bottomline-execution/models/geographical-map/trip-stop-on-map", "@ember/object/computed"], function (_exports, _tripStopOnMap, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStopOnMap.default.extend({
    tank: null,
    iconName: 'fillingstation-tank',
    idForMap: (0, _computed.alias)('tripStop.id'),
    latitude: (0, _computed.alias)('tank.storage.latitude'),
    longitude: (0, _computed.alias)('tank.storage.longitude'),
    zIndex: 30
  });

  _exports.default = _default;
});