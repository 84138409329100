define("bottomline-execution/models/bx/load-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    depotId: attr('string'),
    externalLoadId: attr('string'),
    leadingVehicleId: attr('string'),
    plannedLoadId: attr('string'),
    transactions: hasMany('load-report-transaction', {
      async: false
    })
  });

  _exports.default = _default;
});