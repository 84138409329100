define("bottomline-execution/models/geographical-map/update-storage-on-map", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    tank: null,
    tripStop: null,
    iconName: 'fillingstation-tank',
    iconTitle: (0, _computed.alias)('tripStop.sitename'),
    latitude: (0, _computed.alias)('tank.storage.latitude'),
    longitude: (0, _computed.alias)('tank.storage.longitude'),
    zIndex: 30
  });

  _exports.default = _default;
});