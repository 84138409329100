define("bottomline-execution/controllers/trip-stops/change-terminal", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/controller", "@ember/service", "@ember/utils", "bottomline-execution/mixins/app-state"], function (_exports, _computed, _runloop, _controller, _service, _utils, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, {
    online: (0, _computed.alias)('appState.online'),
    i18n: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    router: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    store: (0, _service.inject)(),
    currentLoadStop: null,
    changeTerminal: function changeTerminal(terminal) {
      var _this = this;

      var currentLoadStop = this.get('currentLoadStop');
      var loadStopProperties = currentLoadStop.getProperties('ActivityCode', 'BarrelCount', 'ConfirmedArrivalDT', 'ConfirmedDepartureDT', 'ConfirmedSequenceNb', 'confirmedQty', 'DriverRemarks', 'ExtOrderID', 'Odometer', 'OrderID', 'plannedQty', 'ResourceID', 'SequenceNb', 'state', 'StorageGroupID', 'TripID', 'TruckID');
      Object.assign(loadStopProperties, {
        'SiteID': terminal.id
      });
      var remark = this.get('store').peekAll('remark').find(function (r) {
        return r.get('type') == 'trip_stop' && r.get('id') == currentLoadStop.get('id');
      });

      if ((0, _utils.isPresent)(remark)) {
        Object.assign(loadStopProperties, {
          'Remarks': remark.get('body')
        });
      }

      var trip = currentLoadStop.get('trip');
      var newLoadStop = trip.get('trip_stops').createRecord(loadStopProperties);
      newLoadStop.save().then(function () {
        currentLoadStop.destroyRecord().then(function () {
          _this.router.transitionTo('trips');
        }).catch(function (error) {
          _this.get('toast').info(_this.get('i18n').t('change_terminal.failure') + error);
        });
      }).catch(function (error) {
        _this.get('toast').info(_this.get('i18n').t('change_terminal.failure') + error);
      });
    },
    actions: {
      selectTerminal: function selectTerminal(terminal) {
        this.set('model.selectedTerminal', terminal);
      },
      changeTerminal: function changeTerminal(terminal) {
        var _this2 = this;

        this.get('f7').confirm(this.get('i18n').t('change_terminal_dialog.warning') + ' ' + terminal.get('Name'), this.get('i18n').t('change_terminal_dialog.title'), (0, _runloop.bind)(this, function () {
          _this2.changeTerminal(terminal);
        }));
      }
    }
  });

  _exports.default = _default;
});