define("bottomline-execution/controllers/edit-truck", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _controller, _object, _computed, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, {
    truckSelector: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    offline: (0, _computed.alias)('appState.offline'),
    router: (0, _service.inject)(),
    sortedTrucks: (0, _object.computed)('model.@each.id', function () {
      return this.get('model').sortBy('id');
    }),
    currentTruck: (0, _computed.alias)('appState.currentTruck'),
    actions: {
      select: function select(truck) {
        var _this = this;

        this.get('truckSelector').select(truck).finally(function () {
          _this.get('f7').showPreloader();

          _this.router.transitionTo('/shifts').then(function () {
            return window.location.reload();
          });
        });
      }
    }
  });

  _exports.default = _default;
});