define("bottomline-execution/models/bx/inventory-correction", ["exports", "bottomline-execution/models/bx/virtual-trip-stop"], function (_exports, _virtualTripStop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */
  var _default = _virtualTripStop.default.extend({
    _confirmTripStop: function _confirmTripStop() {}
  });

  _exports.default = _default;
});