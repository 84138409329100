define("bottomline-execution/utils/delivery-ticket-nb", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deliveryTicketNb = deliveryTicketNb;
  _exports.normalizeTicketNb = normalizeTicketNb;
  _exports.serializeTicketNb = serializeTicketNb;

  function deliveryTicketNb(truckId, meterIndex, ticketNb) {
    return "".concat(truckId).concat(String(new Date().getFullYear()).substring(2)).concat(Number(meterIndex) + 1).concat(String(ticketNb).slice(-4));
  }

  function normalizeTicketNb(ticketNb) {
    if (typeof ticketNb != 'string') return ticketNb;
    return ticketNb.replaceAll(';', '.');
  }

  function serializeTicketNb(ticketNb) {
    var ticketNumbers = extractAlphaNumericParts(ticketNb);
    return ticketNumbers.join(';');
  }

  function extractAlphaNumericParts(ticketNumbers) {
    var alphaNumericRegExp = new RegExp("[^a-zA-Z0-9]*(?<ticketNb>[a-zA-Z0-9]*)(?<remainder>.*)");
    var alphaNumericParts = [];
    var parsableTicketNumbers = ticketNumbers;

    while ((0, _utils.isPresent)(parsableTicketNumbers)) {
      var match = alphaNumericRegExp.exec(parsableTicketNumbers);
      alphaNumericParts.push(match.groups.ticketNb);
      parsableTicketNumbers = match.groups.remainder;
    }

    return alphaNumericParts.filter(function (part) {
      return (0, _utils.isPresent)(part);
    });
  }
});