define("bottomline-execution/models/bx/picture", ["exports", "bottomline-execution/models/picture", "@ember/object", "@ember/utils"], function (_exports, _picture, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _picture.default.extend({
    fullUrl: (0, _object.computed)('imageData', function () {
      if ((0, _utils.isPresent)(this.get('imageData'))) {
        return this.get('imageDataUrl');
      } else {
        return this.get('imageUnavailableUrl');
      }
    })
  });

  _exports.default = _default;
});