define("bottomline-execution/initializers/shared", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(container) {
    var sharedStore = _object.default.create();

    container.registerOptionsForType('globals', {
      instantiate: false,
      singleton: true
    });
    container.register('globals:shared', sharedStore);
  }

  var _default = {
    name: 'shared',
    initialize: initialize
  };
  _exports.default = _default;
});