define("bottomline-execution/serializers/material", ["exports", "ember-data", "active-model-adapter"], function (_exports, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this._super.apply(this, arguments);
      }
    },
    forReport: function forReport(snapshot) {
      return Object.assign({}, snapshot.attributes());
    }
  });

  _exports.default = _default;
});