define("bottomline-execution/templates/components/bl-components/button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r0aDYPIt",
    "block": "[[[11,\"button\"],[16,1,[30,0,[\"idName\"]]],[16,0,[29,[\"button button-fill \",[30,0,[\"classes\"]]]]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[4,[38,0],[[30,0],\"click\"],[[\"bubbles\"],[false]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"key\"],[[30,0,[\"translationKey\"]]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"if\",\"has-block\",\"yield\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/bl-components/button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});