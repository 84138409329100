define("bottomline-execution/routes/trip-stops/unload-stop", ["exports", "@ember/routing/route", "@ember/utils", "bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages"], function (_exports, _route, _utils, _plannedOrConfirmedTripStopStorages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_plannedOrConfirmedTripStopStorages.default, {
    setupController: function setupController(controller, model) {
      controller.resetCompartmentFilters();

      this._super(controller, model);
    },
    _tripStopStorages: function _tripStopStorages(tripStop) {
      var plannedOrConfirmedTripStopStorages = this.plannedOrConfirmedTripStopStorages({
        tripStop: tripStop,
        tripStopStorages: tripStop.get('trip_stop_storages'),
        storageGroupID: tripStop.get('StorageGroupID'),
        includeUnplannedStorages: false
      });

      if ((0, _utils.isPresent)(plannedOrConfirmedTripStopStorages)) {
        return plannedOrConfirmedTripStopStorages;
      } else {
        return tripStop.get('trip_stop_storages');
      }
    }
  });

  _exports.default = _default;
});