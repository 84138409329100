define("bottomline-execution/models/customer", ["exports", "ember-data", "@ember/object/computed", "bottomline-execution/mixins/app-state"], function (_exports, _emberData, _computed, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_appState.default, {
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    _unrestrictedDeliveryTickets: (0, _computed.not)('truckSettings.restrict_delivery_tickets_for_customer_type'),
    _unrestrictedAddStopFromOrder: (0, _computed.not)('truckSettings.customer_type_to_filter_orders')
  });

  _exports.default = _default;
});