define("bottomline-execution/helpers/formatDateTime", ["exports", "@ember/component/helper", "@ember/object/computed", "bottomline-execution/mixins/app-state"], function (_exports, _helper, _computed, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend(_appState.default, {
    dateTimeFormat: (0, _computed.alias)('appState.truckSettings.date_time_format'),
    compute: function compute(value) {
      var dateTimeFormat = this.get('dateTimeFormat').toString();
      var date = new Date(value);
      if (date.toString() === 'Invalid Date') return '';
      return moment(date).format(dateTimeFormat);
    }
  });

  _exports.default = _default;
});