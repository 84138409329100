define("bottomline-execution/services/user-settings", ["exports", "@ember/service", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _service, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_appState.default, {
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),
    currentSettings: (0, _object.computed)('session.currentUser', function () {
      var currentUser = this.get('session.currentUser');
      return this._peekRecord(currentUser) || this._createRecord(currentUser);
    }),
    _peekRecord: function _peekRecord(currentUser) {
      return this.get('store').peekRecord('user-setting', currentUser);
    },
    _createRecord: function _createRecord(currentUser) {
      var userSettings = this.get('store').createRecord('user-setting', {
        id: currentUser
      });

      this._initializeUserSettings(userSettings);

      return userSettings;
    },
    _initializeUserSettings: function _initializeUserSettings(userSettings) {
      var _this = this;

      Object.keys(userSettings.serialize()).forEach(function (settingName) {
        var truckSettingValue = _this.get('appState.truckSettings').get(settingName);

        userSettings.set(settingName, truckSettingValue);
      });
    },
    _userSettingsEqualTruckSettings: function _userSettingsEqualTruckSettings() {
      var userSettings = this.get('currentSettings');
      var truckSettings = this.get('appState.truckSettings');
      var settingsAreEqual = Object.keys(userSettings.serialize()).every(function (settingName) {
        return userSettings.get(settingName) === truckSettings.get(settingName);
      });
      return settingsAreEqual;
    },
    rollbackChanges: function rollbackChanges() {
      var currentSettings = this.get('currentSettings');
      currentSettings.rollbackAttributes();
      if (currentSettings.get('isDeleted')) currentSettings.unloadRecord();
      this.notifyPropertyChange('currentSettings');
    },
    delete: function _delete() {
      this.get('currentSettings').destroyRecord();
      this.notifyPropertyChange('currentSettings');
    },
    save: function save() {
      if (this._userSettingsEqualTruckSettings()) {
        this.get('currentSettings').destroyRecord();
        this.notifyPropertyChange('currentSettings');
      } else {
        this.get('currentSettings').save();
      }
    }
  });

  _exports.default = _default;
});