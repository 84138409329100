define("bottomline-execution/components/customer-domain", ["exports", "@ember/component", "@ember/object", "bottomline-execution/utils/url-utils"], function (_exports, _component, _object, _urlUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    protocols: ['https://', 'http://'],
    customerDomainProtocol: (0, _object.computed)('_customerDomainProtocol', {
      get: function get() {
        return this.get('_customerDomainProtocol') || this.get('protocols.firstObject');
      },
      set: function set(_propertyName, newValue) {
        this.set('_customerDomainProtocol', newValue);
        return newValue;
      }
    }),
    customerDomainName: (0, _object.computed)('_customerDomainName', {
      get: function get() {
        return this.get('_customerDomainName') || '';
      },
      set: function set(_propertyName, newValue) {
        this.set('_customerDomainName', newValue);
        return newValue;
      }
    }),
    customerDomain: (0, _object.computed)('customerDomainProtocol', 'customerDomainName', {
      get: function get() {
        return "".concat(this.get('customerDomainProtocol')).concat(this.get('customerDomainName'));
      },
      set: function set(_propertyName, newValue) {
        var customerDomainProtocol = _urlUtils.default.extractProtocol(newValue);

        var customerDomainName = _urlUtils.default.extractUrl(newValue);

        this.set('_customerDomainProtocol', customerDomainProtocol);
        this.set('_customerDomainName', customerDomainName);
        return newValue;
      }
    })
  });

  _exports.default = _default;
});