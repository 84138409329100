define("bottomline-execution/mixins/unit-of-calculation", ["exports", "@ember/object/mixin", "@ember/object/computed", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _mixin, _computed, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_appState.default, {
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    unitOfCalculation: (0, _computed.alias)('truckSettings.unit_of_calculation'),
    ambientLiters: 'ambient liters',
    unitOfCalculationQty: (0, _object.computed)(function () {
      return this.get('unitOfCalculation') == this.ambientLiters ? 'confirmedQty' : 'confirmed15Qty';
    }),
    unitOfCalculationVolume: (0, _object.computed)(function () {
      return this.get('unitOfCalculation') == this.ambientLiters ? 'volume_in_ambient_liters' : 'volume_in_standard_liters';
    })
  });

  _exports.default = _default;
});