define("bottomline-execution/mixins/app-state", ["exports", "@ember/object/mixin", "@ember/object", "@ember/service"], function (_exports, _mixin, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    store: (0, _service.inject)(),
    appState: (0, _object.computed)(function () {
      return this.get('store').peekRecord('state', '1');
    })
  });

  _exports.default = _default;
});