define("bottomline-execution/services/scanner", ["exports", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_appState.default, {
    retries: 0,
    scan: function scan(successFunc) {
      var _this = this;

      if (!escan) {
        alert('No scanner found');
        return;
      }

      this.set('retries', 0);
      var ssid = this.get('appState.scanner_ssid');
      var pass = this.get('appState.scanner_password');
      setTimeout(function () {
        WifiWizard.startScan(function () {
          // scan successful
          escan.ConnectToWifi(function (success) {
            console.log("".concat(ssid, ": ").concat(success));
            setTimeout(function () {
              _this.scanPaper(successFunc);
            }, 2000);
          }, function (fail) {
            alert("Cannot connect to ".concat(ssid, ", check settings"));
            console.log(fail);
          }, ssid, pass);
        }, function () {
          // scan failed
          console.log('Cannot scan wifi networks');
        });
        escan.Done(function () {
          console.log('Done with scanning, reconnecting to original network.');
        });
      }, 300);
    },
    scanPaper: function scanPaper(successFunc) {
      var _this2 = this;

      console.log("Connecting to \"".concat(this.get('appState.scanner_ssid'), "\", attempt: ").concat(this.get('retries')));
      escan.Scan(function (result) {
        successFunc(result);
      }, function (noscan) {
        console.log(noscan);

        _this2.set('retries', _this2.get('retries') + 1);

        if (_this2.get('retries') > 30) {
          console.log('Did not scan, number of retries exceeded.');
          return;
        }

        setTimeout(function () {
          return _this2.scanPaper(successFunc);
        }, 2000);
      });
    }
  });

  _exports.default = _default;
});