define("bottomline-execution/components/input-number-spinner", ["exports", "bottomline-execution/components/input-number-base"], function (_exports, _inputNumberBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inputNumberBase.default.extend({
    _inputField: function _inputField() {
      return this.get('element').querySelector('input');
    },
    actions: {
      increaseValue: function increaseValue() {
        this._changeValue(+1);

        this._inputField().focus();
      },
      decreaseValue: function decreaseValue() {
        this._changeValue(-1);

        this._inputField().focus();
      },
      onFocus: function onFocus() {
        var action = this.get('actionOnFocus');
        if (action) action();
      }
    }
  });

  _exports.default = _default;
});