define("bottomline-execution/controllers/trip-stops/new/from-order", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "@ember/runloop", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/map-element-data", "@ember/utils", "moment", "bottomline-execution/utils/sort-utils", "bottomline-execution/utils/json-util", "bottomline-execution/utils/grouped-totals"], function (_exports, _computed, _controller, _object, _service, _runloop, _appState, _mapElementData, _utils, _moment, _sortUtils, _jsonUtil, _groupedTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _mapElementData.default, {
    locationService: (0, _service.inject)('location'),
    offline: (0, _computed.alias)('appState.offline'),
    online: (0, _computed.alias)('appState.online'),
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    router: (0, _service.inject)(),
    isRequesting: false,
    lastRequestStartTimestamp: null,
    sortBy: ['distance:asc'],
    orderDetails: (0, _computed.sort)('filteredOrderDetails', 'sortBy'),
    init: function init() {
      if (this.get('online')) {
        this.get('appState').set('toggleShowMap', true);
      }

      this._super.apply(this, arguments);
    },
    _filterOrderDetails: function _filterOrderDetails(orderDetails) {
      var _this = this;

      return orderDetails.filter(function (orderDetail) {
        return _this._qualifiesForNewTripStop(orderDetail) && orderDetail.get('EndDT') >= (0, _moment.default)().subtract(1, 'days') && !['C'].includes(orderDetail.get('OrderStatus')) && _this.get('materialsOnTruckList').includes(orderDetail.get('MaterialNameLong'));
      });
    },
    _qualifiesForNewTripStop: function _qualifiesForNewTripStop(orderDetail) {
      return orderDetail.get('customer.canAddStopFromOrder');
    },
    _queryBounds: function _queryBounds() {
      var _this2 = this;

      var requestStartTimestamp = Date.now();
      this.set('isRequesting', true);
      return this.get('store').query('order-detail', {
        bounds: this.get('bounds'),
        trip_id: this.get('model.tripId')
      }).then(function (result) {
        if (requestStartTimestamp < _this2.get('lastRequestStartTimestamp')) return;

        _this2.set('lastRequestStartTimestamp', requestStartTimestamp);

        _this2.set('model.orderDetails', result);
      }).finally(function () {
        _this2.set('isRequesting', false);
      });
    },
    filteredOrderDetails: (0, _object.computed)('model.orderDetails', function () {
      return this._filterOrderDetails(this.get('model.orderDetails')).uniqBy('OrderID');
    }),
    groupedOrderDetails: (0, _object.computed)('filteredOrderDetails', function () {
      return (0, _groupedTotals.groupedTotalsFor)(this.get('filteredOrderDetails'), {
        groupBy: 'material',
        calculateSumFor: 'MinOrderQty'
      });
    }),
    materialsOnTruckDeliveryCompatibilityList: (0, _object.computed)('model.materialsOnTruck', function () {
      return this.get('model.materialsOnTruck').map(function (materialReference) {
        return materialReference.get('material.DeliveryCompatibility');
      });
    }),
    materialsOnTruckList: (0, _object.computed)('model.materialsOnTruck', function () {
      return this.get('model.materialsOnTruck').map(function (materialReference) {
        return materialReference.get('material.MaterialNameLong');
      });
    }),
    ordersOnTheMap: (0, _object.computed)('filteredOrderDetails.@each.selected', function () {
      var _this3 = this;

      return this.get('filteredOrderDetails').map(function (order) {
        return _this3.getOrderOnMapInfo(order);
      });
    }),
    iconSetForInitialZoomGeoMap: (0, _object.computed)('appState.selectedTrip', function () {
      var tripStops = this.get('appState.selectedTrip.displayableTripStops');
      return this.getIconSetForInitialZoomMapAddNewStop(tripStops);
    }),
    actions: {
      updateContentMapUsingNewBounds: function updateContentMapUsingNewBounds(bounds) {
        this.set('bounds', bounds);
        this.set('mapBoundDebounce', (0, _runloop.debounce)(this, this._queryBounds, 2000));
      },
      selectOrderDetail: function selectOrderDetail(orderDetail) {
        var _this4 = this;

        this.get('filteredOrderDetails').setEach('selected', false);
        orderDetail.set('selected', true);
        this.set('model.selectedOrderDetail', orderDetail);
        this.get('store').find('site', orderDetail.get('SiteID')).then(function (site) {
          _this4.set('model.selectedSite', site);
        });
        this.get('store').find('order', orderDetail.get('OrderID')).then(function (order) {
          _this4.set('model.selectedOrder', order);
        });
      },
      selectOrder: function selectOrder(orderId) {
        var orderDetail = this.get('store').peekRecord('order-detail', orderId);
        this.send('selectOrderDetail', orderDetail);
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      createTripStop: function createTripStop(orderDetail) {
        var _this5 = this;

        this.set('isCreatingNewTripStop', true);
        var tripId = this.get('model.tripId');
        var trip = this.get('store').peekRecord('trip', tripId);
        var truckInventories = trip.get('shift.truck_inventories').filter(function (truckInventory) {
          return [_this5.get('appState.currentTruck'), _this5.get('appState.currentTrailer')].includes(truckInventory.get('truck_id'));
        }).sort(function (a, b) {
          return (0, _sortUtils.alphaNumericSort)(a.get('truck_storage_id'), b.get('truck_storage_id'));
        });
        var compatibleTruckInventory = truckInventories.filterBy('material_id', orderDetail.get('MaterialID')).get('firstObject');

        if ((0, _utils.isEmpty)(compatibleTruckInventory)) {
          compatibleTruckInventory = truckInventories.filterBy('material.DeliveryCompatibility', orderDetail.get('DeliveryCompatibility')).get('firstObject');
        } // set plannedQty to 0 instead of OrderQty for trip stops created from orders,
        // that way we can distinguish regular unload stops from added from orders
        // and handle filtering of trip stop storages in a correct way


        var plannedQty = 0;
        var newTripStop = this.get('store').createRecord('trip-stop', {
          ActivityCode: 'U',
          OrderID: orderDetail.get('OrderID'),
          ExtOrderID: orderDetail.get('OrderExtOrderId'),
          StorageGroupID: orderDetail.get('StorageGroupID'),
          plannedQty: plannedQty,
          trip: trip,
          TripID: tripId,
          state: 'planned',
          SiteID: orderDetail.get('SiteID'),
          TruckID: trip.get('TruckID'),
          ResourceID: trip.get('ResourceID'),
          DriverRemarks: this.get('i18n').t('driver_added_stop.order') + ' ' + (0, _moment.default)().format('L LTS')
        });
        newTripStop.save().then((0, _runloop.bind)(function (tripStop) {
          _this5.get('toast').info(_this5.get('i18n').t('add_stop_from_order_success'));

          var tripStopTruckStorage = tripStop.get('trip_stop_truck_storages').get('lastObject');
          var tripStopTruckStorageCopyData = Object.assign({}, (0, _jsonUtil.toJSON)(tripStopTruckStorage), {
            id: newTripStop.createGuid('for add stop from order'),
            ActivityCode: null,
            TripStopStorageID: null,
            TruckStorageID: compatibleTruckInventory.get('truck_storage_id'),
            TruckID: compatibleTruckInventory.get('truck_id')
          });

          var newStopTruckStorage = _this5.get('store').createRecord('trip-stop-truck-storage', tripStopTruckStorageCopyData);

          tripStop.get('trip_stop_truck_storages').pushObject(newStopTruckStorage);
          return trip.save().then(function () {
            (0, _runloop.cancel)(_this5.get('mapBoundDebounce'));

            _this5.set('isCreatingNewTripStop', false);

            _this5.router.transitionTo('trips');
          });
        })).catch(function () {
          _this5.set('isCreatingNewTripStop', false);

          _this5.get('toast').error('Unexpected error occured');
        });
      }
    }
  });

  _exports.default = _default;
});