define("bottomline-execution/components/geographical-maps/location-update-map", ["exports", "bottomline-execution/components/geographical-maps/base-map", "@ember/service", "@ember/utils", "@ember/runloop"], function (_exports, _baseMap, _service, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMap.default.extend({
    i18n: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    cancel: null,
    updateLocation: null,
    icon: null,
    newLocationMarker: null,
    updatableEntityIcon: null,
    newLatitude: null,
    newLongitude: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._renderMap();
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _runloop.next)(function () {
        _this._getGeographicalMap().invalidateSize();

        _this._applyDefaultZoom();
      });
    },
    _defaultMapBounds: function _defaultMapBounds(icons) {
      var coordinates;

      if ((0, _utils.isPresent)(this.get('currentLocationIcon'))) {
        coordinates = this._coordinatesBasedOnCurrentLocation();
      } else {
        coordinates = this._coordinatesBasedOnIcons(icons);
      }

      return this._calculateMapBounds(coordinates);
    },
    _coordinatesBasedOnCurrentLocation: function _coordinatesBasedOnCurrentLocation() {
      var coordinatesUpdatableEntityIcon = this.get('updatableEntityIcon').getLatLng();

      var mirroredLocation = this._mirroredCoordinates(coordinatesUpdatableEntityIcon.lat, coordinatesUpdatableEntityIcon.lng);

      return {
        iconLatitudes: [coordinatesUpdatableEntityIcon.lat, mirroredLocation.lat],
        iconLongitudes: [coordinatesUpdatableEntityIcon.lng, mirroredLocation.lng]
      };
    },
    _mirroredCoordinates: function _mirroredCoordinates(lat, lng) {
      var mirrorPositionLat = this.get('currentLocationCoordinates')[0];
      var mirrorPositionLng = this.get('currentLocationCoordinates')[1];
      var relativePositionLat = lat - mirrorPositionLat;
      var relativePositionLng = lng - mirrorPositionLng;
      var flippedLat = -1 * relativePositionLat;
      var flippedLng = -1 * relativePositionLng;
      return {
        lat: mirrorPositionLat + flippedLat,
        lng: mirrorPositionLng + flippedLng
      };
    },
    _iconsToShowOnDefaultZoom: function _iconsToShowOnDefaultZoom() {
      var icon = this.get('icon');
      var iconsToIncludeInDefaultZoom = [icon];
      var newLocationMarker = this.get('newLocationMarker');
      var coordinates = newLocationMarker.getLatLng();
      iconsToIncludeInDefaultZoom.push(this._markerForIcons(newLocationMarker, coordinates));
      return iconsToIncludeInDefaultZoom;
    },
    _addControlsForMap: function _addControlsForMap(_geographicalMap) {
      this._super.apply(this, arguments);
    },
    _renderIcons: function _renderIcons(geographicalMap) {
      if ((0, _utils.isEmpty)(geographicalMap)) return;

      var coordinates = this._coordinatesInDatabaseForCurrentLocation();

      this._renderIconForLocationUpdater(geographicalMap, coordinates);

      this._renderNewLocationMarker(geographicalMap, coordinates);

      this._hideCurrentLocationIcon();

      this._defineNewLocationMarkerUpdate(geographicalMap);
    },
    _hideCurrentLocationIcon: function _hideCurrentLocationIcon() {
      var newLocationMarker = this.get('newLocationMarker');
      var newLocationMarkerCoordinates = newLocationMarker.getLatLng();
      var currentLocationCoordinates = this.get('currentLocationCoordinates');

      var distance = this._pointsDistance(newLocationMarkerCoordinates.lat, newLocationMarkerCoordinates.lng, currentLocationCoordinates[0], currentLocationCoordinates[1]);

      var opacity = 1;

      if (distance < 50) {
        opacity = distance / 50;
      }

      var currentLocationIcon = this.get('currentLocationIcon');

      if ((0, _utils.isPresent)(currentLocationIcon)) {
        currentLocationIcon.setOpacity(opacity);
      }
    },
    _pointsDistance: function _pointsDistance(lat1, lon1, lat2, lon2) {
      var earthRadius = 6378.137; // kilometers

      var latDistance = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
      var lonDistance = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
      var a = Math.sin(latDistance / 2) * Math.sin(latDistance / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(lonDistance / 2) * Math.sin(lonDistance / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var distance = earthRadius * c;
      return distance * 1000; // meters
    },
    _coordinatesInDatabaseForCurrentLocation: function _coordinatesInDatabaseForCurrentLocation() {
      return [this.get('model.latitude'), this.get('model.longitude')];
    },
    _renderIconForLocationUpdater: function _renderIconForLocationUpdater(geographicalMap, coordinates) {
      if ((0, _utils.isEmpty)(coordinates)) return;
      var iconToShow = this.get('updatableEntityIcon');

      if ((0, _utils.isEmpty)(iconToShow)) {
        var icon = this.get('icon');
        iconToShow = this._createIcon(icon, coordinates, geographicalMap);
        this.set('updatableEntityIcon', iconToShow);
      } else {
        this.set('updatableEntityIcon', iconToShow.setLatLng(this._coordinatesInDatabaseForCurrentLocation()));
        iconToShow.addTo(geographicalMap);
      }

      iconToShow.openPopup();
    },
    _createIcon: function _createIcon(icon, coordinates, geographicalMap) {
      var iconToShow = new L.marker(coordinates, {
        title: icon.get('iconTitle'),
        icon: this._icon(icon),
        zIndexOffset: this._iconZIndex(icon),
        opacity: this._iconOpacity(icon)
      });

      this._bindPopup(iconToShow, icon);

      iconToShow.addTo(geographicalMap);
      return iconToShow;
    },
    _renderNewLocationMarker: function _renderNewLocationMarker(geographicalMap, coordinates) {
      var coordinatesForMarker = this._coordinatesForMarker(coordinates);

      var marker = this.get('newLocationMarker');

      if ((0, _utils.isEmpty)(marker)) {
        marker = L.marker(coordinatesForMarker, {
          opacity: 0.4,
          zIndexOffset: 1600
        }).bindTooltip("".concat(this.get('i18n').t('new_location')), {
          permanent: true
        });
        this.set('newLocationMarker', marker);
      } else {
        this.set('newLocationMarker', marker.setLatLng(coordinates));
      }

      marker.addTo(geographicalMap).update();
    },
    _defineNewLocationMarkerUpdate: function _defineNewLocationMarkerUpdate(geographicalMap) {
      var _this2 = this;

      geographicalMap.on('move', function () {
        var marker = _this2.get('newLocationMarker');

        marker.setLatLng(geographicalMap.getCenter());
      });
      geographicalMap.on('dragend zoomend', function () {
        var mapCenter = geographicalMap.getCenter();

        var marker = _this2.get('newLocationMarker');

        marker.setLatLng(mapCenter);
        var lat = mapCenter.lat,
            lng = mapCenter.lng;

        _this2._updateNewLatLng(lat, lng);

        if ((0, _utils.isPresent)(_this2.get('currentLocationIcon'))) {
          _this2.get('currentLocationIcon').addTo(geographicalMap).update();

          _this2._hideCurrentLocationIcon();
        }
      });
    },
    _updateNewLatLng: function _updateNewLatLng(lat, lng) {
      this.setProperties({
        newLatitude: lat,
        newLongitude: lng
      });
    },
    actions: {
      cancel: function cancel() {
        this.get('cancel')();
      },
      confirm: function confirm() {
        try {
          this.get('updateLocation')(this.get('model'), this.get('newLatitude'), this.get('newLongitude'));
          this.get('toast').info(this.get('i18n').t('location_confirmer.success'));
          this.notifyPropertyChange('icons');
        } catch (error) {
          this.get('toast').error(this.get('i18n').t('location_confirmer.error'));
        } finally {
          this.get('cancel')();
        }
      }
    }
  });

  _exports.default = _default;
});