define("bottomline-execution/mixins/remote-logging", ["exports", "@ember/object/mixin", "bottomline-execution/config/environment", "bottomline-execution/mixins/app-state"], function (_exports, _mixin, _environment, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_appState.default, {
    _remoteLoggingEnabled: function _remoteLoggingEnabled() {
      return _environment.default.environment != 'test' && this.get('appState.application_logging');
    }
  });

  _exports.default = _default;
});