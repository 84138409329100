define("bottomline-execution/serializers/bx/load-report", ["exports", "active-model-adapter", "@ember/service"], function (_exports, _activeModelAdapter, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    store: (0, _service.inject)(),
    normalizeQueryResponse: function normalizeQueryResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var loadTransactionSerializer = this.get('store').serializerFor('load-report-transaction');
      var mappedLoadTransactions = loadTransactionSerializer.mapResponse(payload.load_report_id, payload.executed_load_transactions);
      var mappedLoadReport = {
        id: payload.load_report_id,
        type: 'load-report',
        attributes: {
          depotId: payload.depot_id,
          externalLoadId: payload.external_load_id,
          leadingVehicleId: payload.leading_vehicle_id,
          plannedLoadId: payload.planned_load_id
        },
        relationships: {
          transactions: {
            data: mappedLoadTransactions.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      return {
        data: [mappedLoadReport],
        included: mappedLoadTransactions.data
      };
    }
  });

  _exports.default = _default;
});