define("bottomline-execution/templates/components/customer-domain", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kTjuf94G",
    "block": "[[[10,2],[12],[1,[28,[35,0],null,[[\"key\"],[\"enter_customer_domain_manually\"]]]],[13],[1,\"\\n\"],[10,\"li\"],[14,0,\"item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label w-auto\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"domain\"]]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input w-auto\"],[12],[1,\"\\n      \"],[10,\"select\"],[15,\"onchange\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"customerDomainProtocol\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"protocols\"]]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,2,[30,1]],[15,\"selected\",[28,[37,5],[[30,1],[30,0,[\"customerDomainProtocol\"]]],null]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[8,[39,6],[[24,\"placeholder\",\"example.com\"],[24,0,\"form-control\"]],[[\"@value\",\"@type\"],[[30,0,[\"customerDomainName\"]],\"text\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"protocol\"],false,[\"editable-t\",\"action\",\"mut\",\"each\",\"-track-array\",\"eq\",\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/customer-domain.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});