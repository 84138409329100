define("bottomline-execution/instance-initializers/user-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    var store = applicationInstance.lookup('service:store');

    if (localStorage['user-setting']) {
      var userSettingRecords = JSON.parse(localStorage['user-setting'])['user-setting'].records;
      var payload = Object.keys(userSettingRecords).map(function (key) {
        return userSettingRecords[key];
      });
      store.pushPayload({
        "user-setting": payload
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});