define("bottomline-execution/components/trips-list-panels", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "bottomline-execution/mixins/app-state"], function (_exports, _component, _object, _computed, _service, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    userSettings: (0, _service.inject)(),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    editPanelSelection: false,
    selectableATGInformation: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_atg_information') == true;
    }),
    selectableATGWarnings: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_atg_warnings') == true;
    }),
    selectableCurrentInventory: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_current_inventory') == true;
    }),
    selectableGeographicalMap: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_geographical_map') == true;
    }),
    selectableMaterialCalculation: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_material_calculation') == true;
    }),
    selectableRemarksForDriver: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_remarks_for_driver') == true;
    }),
    selectableSiteDetails: (0, _object.computed)('editPanelSelection', function () {
      return this.get('editPanelSelection') && this.get('truckSettings.trips_panel_site_details') == true;
    }),
    actions: {
      togglePanelSelection: function togglePanelSelection() {
        this.toggleProperty('editPanelSelection');
      },
      cancelPanelSelection: function cancelPanelSelection() {
        this.get('userSettings').rollbackChanges();
        this.toggleProperty('editPanelSelection');
      },
      savePanelSelection: function savePanelSelection() {
        this.get('userSettings').save();
        this.toggleProperty('editPanelSelection');
      },
      resetToTruckSettings: function resetToTruckSettings() {
        this.get('userSettings').delete();
        this.toggleProperty('editPanelSelection');
      }
    }
  });

  _exports.default = _default;
});