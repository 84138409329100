define("bottomline-execution/controllers/trip-stops/end-inventory", ["exports", "bottomline-execution/controllers/trip-stops/show", "@ember/object/computed", "@ember/object"], function (_exports, _show, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend({
    init: function init() {
      var showMaterialsOnEndInventory = this.get('truckSettings.show_materials_on_end_inventory');
      this.set('showFilteredMaterials', showMaterialsOnEndInventory !== false);
      this.set('showAllMaterials', showMaterialsOnEndInventory === 'all');

      this._super.apply(this, arguments);
    },
    isCaptureRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_end_inventory_mandatory_picture') && this.get('truckSettings.on_end_inventory_capture') && !this.get('model.hasPictures');
    }),
    isScanRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_end_inventory_mandatory_picture') && this.get('truckSettings.on_end_inventory_scan') && !this.get('model.hasPictures');
    }),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_unload'),
    showAllMaterials: null,
    showFilteredMaterials: null,
    showMaterialsPanel: (0, _computed.alias)('truckSettings.end_inventory_panel_materials'),
    showOdometerInput: (0, _object.computed)(function () {
      return ['optional', 'mandatory'].includes(this.get('truckSettings.odometer_on_end_inventory'));
    }),
    showStandardLiters: (0, _computed.alias)('truckSettings.show_standard_liters_on_unload'),
    requireSignatures: (0, _computed.alias)('truckSettings.on_end_inventory_mandatory_signatures'),
    relevantMaterials: (0, _object.computed)('model.tripStopStorageByTruckInventory.[]', function () {
      return this.get('model.tripStopStorageByTruckInventory').filter(function (tss) {
        return tss.currentInventory > 0;
      });
    }),
    allMaterials: (0, _computed.alias)('model.tripStopStorageByTruckInventory'),
    filteredMaterials: (0, _object.computed)('relevantMaterials.[]', 'allMaterials.[]', 'showAllMaterials', 'showFilteredMaterials', function () {
      if (this.get('showAllMaterials')) return this.get('allMaterials');
      if (this.get('showFilteredMaterials')) return this.get('relevantMaterials');
      return [];
    }),
    showTruckDetails: true,
    showTrailerDetails: true,
    actions: {
      toggleShowFilteredMaterials: function toggleShowFilteredMaterials() {
        this.toggleProperty('showFilteredMaterials');

        if (!this.get('showFilteredMaterials')) {
          this.set('showAllMaterials', false);
        }
      },
      toggleShowAllMaterials: function toggleShowAllMaterials() {
        this.toggleProperty('showAllMaterials');

        if (this.get('showAllMaterials')) {
          this.set('showFilteredMaterials', true);
        }
      },
      truckIsEmpty: function truckIsEmpty() {
        this.get('model.trip_stop_truck_storages').setEach('confirmedQty', 0);
        this.get('model.trip_stop_truck_storages').setEach('confirmed15Qty', 0);
        this.get('model.trip_stop_storages').setEach('confirmedQty', 0);
        this.get('model.trip_stop_storages').setEach('confirmed15Qty', 0);
        this.get('model.truckInventories').setEach('currentQty', 0);
        this.get('model.trailerInventories').setEach('currentQty', 0);
        return false;
      }
    }
  });

  _exports.default = _default;
});