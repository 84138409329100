define("bottomline-execution/controllers/trip-stops/new/from-site", ["exports", "@ember/object/computed", "@ember/array", "@ember/object", "@ember/controller", "@ember/service", "@ember/utils", "@ember/runloop", "moment", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/map-element-data"], function (_exports, _computed, _array, _object, _controller, _service, _utils, _runloop, _moment, _appState, _mapElementData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _mapElementData.default, {
    offline: (0, _computed.alias)('appState.offline'),
    online: (0, _computed.alias)('appState.online'),
    toast: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    router: (0, _service.inject)(),
    isRequesting: false,
    lastRequestStartTimestamp: null,
    invertedWidth: false,
    selectedStorages: [],
    sites: (0, _array.A)(),
    isCreatingNewTripStop: false,
    init: function init() {
      if (this.get('online')) {
        this.get('appState').set('toggleShowMap', true);
      }

      this._super.apply(this, arguments);
    },
    sitesOnTheMap: (0, _object.computed)('sites.@each.selected', function () {
      var _this = this;

      return this.get('sites').map(function (site) {
        return _this.getSiteOnMapInfo(site);
      }, []);
    }),
    iconSetForInitialZoomGeoMap: (0, _object.computed)('appState.selectedTrip', function () {
      var tripStops = this.get('appState.selectedTrip.displayableTripStops');
      return this.getIconSetForInitialZoomMapAddNewStop(tripStops);
    }),
    invalidSelection: (0, _object.computed)('selectedStorages', 'selectedSite', function () {
      return (0, _utils.isEmpty)(this.get('selectedSite')) || (0, _utils.isEmpty)(this.get('selectedStorages'));
    }),
    selectedSite: (0, _object.computed)('sites.@each.selected', function () {
      return (0, _array.A)(this.get('sites')).findBy('selected', true);
    }),
    currentTrip: (0, _computed.alias)('appState.selectedTrip'),
    storageGroupIds: (0, _object.computed)('selectedStorages', function () {
      return this.get('selectedStorages').mapBy('StorageGroupID');
    }),
    uniqueStorageGroupIds: (0, _computed.uniq)('storageGroupIds'),
    materialIdsOnTruck: (0, _object.computed)('materialsOnTruck', function () {
      return this.get('materialsOnTruck').map(function (materialReference) {
        return materialReference.get('material.id');
      });
    }),
    bySequenceNb: ['SequenceNb'],
    selectedSiteStorages: (0, _computed.sort)('selectedSite.storages', 'bySequenceNb'),
    _queryBounds: function _queryBounds() {
      var _this2 = this;

      var materialIds = this.get('materialIdsOnTruck');

      if ((0, _utils.isEmpty)(materialIds)) {
        return;
      }

      this.set('sites', []);
      var requestStartTimestamp = Date.now();
      this.set('isRequesting', true);
      return this.get('store').query('site', {
        bounds: this.get('bounds'),
        material_owner_ids: this.get('currentTrip.materialOwners'),
        filter_heating_oil_customers: true,
        region_id: this.get('currentTrip.shift.RegionID'),
        material_ids: materialIds,
        page: 1
      }).then(function (sites) {
        if (requestStartTimestamp < _this2.get('lastRequestStartTimestamp')) return;

        _this2.set('lastRequestStartTimestamp', requestStartTimestamp);

        _this2.get('sites').addObjects(sites.toArray());
      }).finally(function () {
        _this2.set('isRequesting', false);
      });
    },
    actions: {
      updateContentMapUsingNewBounds: function updateContentMapUsingNewBounds(bounds) {
        this.set('bounds', bounds);
        this.set('mapBoundDebounce', (0, _runloop.debounce)(this, this._queryBounds, 2000));
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      select: function select(site) {
        this.get('sites').setEach('selected', false);
        site.set('selected', true);
        return false;
      },
      selectSite: function selectSite(siteId) {
        var site = this.get('store').peekRecord('site', siteId);
        this.send('select', site);
      },
      clearSelection: function clearSelection() {
        this.set('selectedStorages', []);
        (this.get('selectedSite.storages') || []).setEach('selected', false);
        return false;
      },
      createTripStop: function createTripStop() {
        var _this3 = this;

        this.set('isCreatingNewTripStop', true);
        this.get('uniqueStorageGroupIds').forEach(function (storageGroupId, index) {
          var tripStop = _this3.get('currentTrip').get('trip_stops').createRecord({
            SiteID: _this3.get('selectedSite').id,
            StorageGroupID: storageGroupId,
            TripID: _this3.get('currentTrip.id'),
            ActivityCode: 'U',
            TruckID: _this3.get('currentTrip.TruckID'),
            ResourceID: _this3.get('currentTrip.ResourceID'),
            plannedQty: 0,
            state: 'planned',
            DriverRemarks: _this3.get('i18n').t('driver_added_stop.site') + ' ' + (0, _moment.default)().format('L LTS')
          });

          tripStop.save().then((0, _runloop.bind)(_this3, function (record) {
            var _this4 = this;

            this.get('toast').info(this.get('i18n').t('create_site_delivery_success'));

            if (index === this.get('uniqueStorageGroupIds').length - 1) {
              record.get('trip').save().then(function () {
                (0, _runloop.cancel)(_this4.get('mapBoundDebounce'));

                _this4.set('isCreatingNewTripStop', false);

                _this4.router.transitionTo('trips.index');
              });
            }
          })).catch(function () {
            _this3.get('toast').error('Unexpected error occured');

            tripStop.unloadRecord();
          });
        });
      }
    }
  });

  _exports.default = _default;
});