define("bottomline-execution/models/compound-vehicle", ["exports", "ember-data/model", "@ember/object", "ember-data"], function (_exports, _model, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    TruckID: attr('string'),
    TrailerID: attr('string'),
    // BP-7011: The truck may have multiple trailers, this is currently not supported by BE
    vehicleIds: (0, _object.computed)(function () {
      return [this.get('TruckID'), this.get('TrailerID')].compact();
    })
  });

  _exports.default = _default;
});