define("bottomline-execution/templates/components/external-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HPxDJRyr",
    "block": "[[[10,3],[15,6,[30,0,[\"href\"]]],[15,1,[30,0,[\"idName\"]]],[14,\"target\",\"_blank\"],[15,0,[29,[\"button \",[30,0,[\"classes\"]]]]],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"key\"],[\"external_reporting\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/external-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});