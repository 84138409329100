define("bottomline-execution/utils/promise", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.promiseWithTimeout = promiseWithTimeout;
  _exports.timeout = timeout;
  _exports.waitForDevice = waitForDevice;

  function timeout(ms) {
    return new _rsvp.default.Promise(function (resolve) {
      return (0, _runloop.later)(function () {
        return resolve();
      }, ms);
    });
  }

  function promiseWithTimeout(promise) {
    var ms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10000;
    var failureMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Cancelled by timeout';
    var timeoutHandle;
    var timeoutPromise = new _rsvp.default.Promise(function (_resolve, reject) {
      timeoutHandle = setTimeout(function () {
        return reject(new Error(failureMessage));
      }, ms);
    });
    return _rsvp.default.Promise.race([promise, timeoutPromise]).then(function (result) {
      clearTimeout(timeoutHandle);
      return result;
    });
  }

  function waitForDevice() {
    return new _rsvp.default.Promise(function (resolve, _reject) {
      document.addEventListener('deviceready', resolve, false);
    });
  }
});