define("bottomline-execution/components/shift-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/trip-sorter"], function (_exports, _computed, _component, _object, _appState, _tripSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, _tripSorter.default, {
    tagName: '',
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    isSelected: (0, _object.computed)('appState.selectedShiftId', function () {
      return this.get('appState.selectedShiftId') === this.get('shift.id');
    }),
    trips: (0, _computed.alias)('shift.trips'),
    areTripsLoaded: (0, _computed.alias)('shift.trips.isFulfilled')
  });

  _exports.default = _default;
});