define("bottomline-execution/serializers/bx/custom-field", ["exports", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    store: (0, _service.inject)(),
    serialize: function serialize(snapshot) {
      var shipperId = snapshot.attr('shipperId');
      var customFields = [this._barrelCount(snapshot, shipperId), this._dropCount(snapshot, shipperId), this._filledUp(snapshot, shipperId)];
      return customFields.flat().compact();
    },
    _barrelCount: function _barrelCount(snapshot, shipperId) {
      var barrelCount = snapshot.attr('BarrelCount');
      if ((0, _utils.isBlank)(barrelCount)) return null;

      var barrelCountDefinitionId = this._barrelCountDefinitionId(shipperId);

      var site = this._site(snapshot);

      return this._customField(barrelCountDefinitionId, site.id, barrelCount);
    },
    _dropCount: function _dropCount(snapshot, shipperId) {
      var _this = this;

      var dropCountDefinitionid = this._dropCountDefinitionId(shipperId);

      return this._tripStopStorages(snapshot).map(function (tripStopStorage) {
        return _this._tripStopStorageCustomField(tripStopStorage, 'DropCount', dropCountDefinitionid);
      });
    },
    _filledUp: function _filledUp(snapshot, shipperId) {
      var _this2 = this;

      var filledUpDefinitionId = this._filledUpDefinitionId(shipperId);

      return this._tripStopStorages(snapshot).map(function (tripStopStorage) {
        return _this2._tripStopStorageCustomField(tripStopStorage, 'TankFilledYN', filledUpDefinitionId, {
          defaultValue: false
        });
      });
    },
    _tripStopStorageCustomField: function _tripStopStorageCustomField(tripStopStorage, attributeName, definitionId) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
        defaultValue: null
      };
      var value = tripStopStorage.attr(attributeName) || options.defaultValue;
      if ((0, _utils.isBlank)(value)) return null;

      var storage = this._storage(tripStopStorage);

      return this._customField(definitionId, storage.id, value);
    },
    _customField: function _customField(definitionId, targetId, value) {
      if ((0, _utils.isBlank)(definitionId)) return null;
      return {
        definition_id: definitionId,
        target_id: targetId,
        value: value.toString()
      };
    },
    _barrelCountDefinitionId: function _barrelCountDefinitionId(shipperId) {
      return this._customFieldDefinitionId(shipperId, 'barrel_count');
    },
    _dropCountDefinitionId: function _dropCountDefinitionId(shipperId) {
      return this._customFieldDefinitionId(shipperId, 'drop_count');
    },
    _filledUpDefinitionId: function _filledUpDefinitionId(shipperId) {
      return this._customFieldDefinitionId(shipperId, 'filled_up');
    },
    _customFieldDefinitionId: function _customFieldDefinitionId(shipperId, name) {
      var customFieldDefinition = this._customFieldDefinitions(shipperId).findBy('name', name) || {};
      return customFieldDefinition.id;
    },
    _customFieldDefinitions: function _customFieldDefinitions(shipperId) {
      return this.get('store').peekAll('custom-field-definition').filterBy('shipperId', shipperId);
    },
    _site: function _site(tripStopSnapshot) {
      return tripStopSnapshot.belongsTo('site');
    },
    _tripStopStorages: function _tripStopStorages(tripStopSnapshot) {
      return tripStopSnapshot.hasMany('trip_stop_storages');
    },
    _storage: function _storage(tripStopStorageSnapshot) {
      return tripStopStorageSnapshot.belongsTo('storage');
    }
  });

  _exports.default = _default;
});