define("bottomline-execution/errors/execution-data-save-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ExecutionDataSaveError;

  function ExecutionDataSaveError() {
    var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Error saving data';
    var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this.name = 'ExecutionDataSaveError', this.message = message, this.payload = payload;
  }
});