define("bottomline-execution/mixins/vehicle-identifier", ["exports", "@ember/object/mixin", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _mixin, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_appState.default, {
    identifier: (0, _object.computed)('appState.useBeBackend', function () {
      if (this.get('appState.useBeBackend')) {
        return this.get('id');
      } else {
        return this.get('displayName');
      }
    })
  });

  _exports.default = _default;
});