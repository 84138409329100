define("bottomline-execution/helpers/includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;

  function includes(array, value) {
    return array.includes(value);
  }

  var _default = (0, _helper.helper)(includes);

  _exports.default = _default;
});