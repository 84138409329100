define("bottomline-execution/mixins/authentication/authenticated-route", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object/computed"], function (_exports, _mixin, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    session: (0, _service.inject)(),
    router: (0, _service.inject)(),
    authenticationRoute: 'login',
    isAuthenticated: (0, _computed.alias)('session.isAuthenticated'),
    beforeModel: function beforeModel() {
      if (!this.get('isAuthenticated')) {
        this.get('router').transitionTo(this.get('authenticationRoute'));
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});