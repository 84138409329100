define("bottomline-execution/routes/shifts/show/reorder-trip-stops", ["exports", "@ember/routing/route", "bottomline-execution/mixins/authentication/authenticated-route"], function (_exports, _route, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRoute.default, {
    model: function model(_params, _transition) {
      return this.modelFor('shifts.show').get('trips').filter(function (trip) {
        return !trip.get('isFinished');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.initializeSelectedTripStop();
    }
  });

  _exports.default = _default;
});