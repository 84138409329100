define("bottomline-execution/models/geographical-map/site-on-map", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    site: null,
    iconName: 'fillingstation',
    iconTitle: (0, _computed.alias)('id'),
    isSelected: (0, _computed.alias)('site.selected'),
    latitude: (0, _computed.alias)('site.latitude'),
    longitude: (0, _computed.alias)('site.longitude')
  });

  _exports.default = _default;
});