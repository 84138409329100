define("bottomline-execution/instance-initializers/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    var store = applicationInstance.lookup('service:store');

    if (localStorage.toggles) {
      var storedRecords = JSON.parse(localStorage.toggles).toggle.records;
      store.pushPayload({
        toggles: Object.values(storedRecords)
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});