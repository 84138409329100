define("bottomline-execution/models/bx/trip", ["exports", "bottomline-execution/models/trip", "@ember/utils"], function (_exports, _trip, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */
  var _default = _trip.default.extend({
    setStartInventory: function setStartInventory() {
      this.startInventoryStop().set('ConfirmedDepartureDT', new Date());
      return this._super.apply(this, arguments);
    },
    finish: function finish() {
      this.set('state', 'finished');
      var finishedBefore = (0, _utils.isPresent)(this.get('ConfirmedEndDT'));
      if (!finishedBefore) this._super.apply(this, arguments);
    },
    restart: function restart() {
      if (this.get('isSkipped')) {
        this.set('ConfirmedEndDT', null);
      }

      this.set('state', 'started');
      return this.save(this.adapterAction('restart'));
    }
  });

  _exports.default = _default;
});