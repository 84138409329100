define("bottomline-execution/router", ["exports", "@ember/routing/router", "bottomline-execution/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Router = _router.default.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('shifts', {
      resetNamespace: true
    }, function () {
      this.route('adr-report', {
        path: ':shift_id/adr-report'
      });
      this.route('show', {
        path: ':shift_id'
      }, function () {
        this.route('trips', {
          resetNamespace: true
        }, function () {
          this.route('show', {
            path: ':trip_id'
          }, function () {
            this.route('trip-stops', {
              resetNamespace: true
            }, function () {
              this.route('new', function () {
                this.route('from-site');
                this.route('from-order');
              });
              this.route('change-terminal');
              this.route('material-transfer');
              this.route('load-stop', {
                path: 'load-stop/:trip_stop_id'
              });
              this.route('unload-stop', {
                path: 'unload-stop/:trip_stop_id'
              });
              this.route('end-inventory', {
                path: 'end-inventory/:end_stop_id'
              });
              this.route('authorize-site', {
                path: 'pre-authorization/:trip_stop_id'
              });
              this.route('authorize-delivery', {
                path: 'post-authorization/:trip_stop_id'
              });
              this.route('scan', {
                path: ':trip_stop_id/scan'
              });
              this.route('camera', {
                path: ':trip_stop_id/capture'
              });
            });
          });
        });
        this.route('reorder-trip-stops');
      });
    });
    this.route('truck-settings');
    this.route('login');
    this.route('settings');
    this.route('edit-truck');
    this.route('loading');
    this.route('hidden-translations', {});
    this.route('dashboard', {
      path: ''
    });
    this.route('index', {
      path: 'index'
    });
  });
  var _default = Router;
  _exports.default = _default;
});