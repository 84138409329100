define("bottomline-execution/models/state", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils", "ember-data", "bottomline-execution/config/environment", "bottomline-execution/mixins/truck-settings-id"], function (_exports, _computed, _object, _service, _utils, _emberData, _environment, _truckSettingsId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var defaultScanner = 'hp';

  var _default = Model.extend(_truckSettingsId.default, {
    store: (0, _service.inject)(),
    init: function init() {
      this._super.apply(this, arguments);

      if ((0, _utils.isEmpty)(this.get('scanner_brand'))) {
        // Set default value in this hook to prevent application store cache data to hold scanner_brand set to null
        // Using `scanner_brand: attr('string', { defaultValue: ... })` will not update value in application store cache`
        this.set('scanner_brand', defaultScanner);
      }
    },
    selectedTripId: attr('string'),
    selectedShiftId: attr('string'),
    selectedTripStopId: attr('string'),
    selectedShiftTripsLoadState: (0, _object.observer)('selectedShift.tripsLoaded', function () {
      this._propagateShiftSelection();
    }),
    startedShift: attr('string'),
    scanner_ssid: attr('string'),
    scanner_password: attr('string'),
    scanner_brand: attr('string'),
    currentTruck: attr('string'),
    currentTrailer: attr('string'),
    currentCompoundVehicle: (0, _computed.alias)('selectedShift.CompoundVehicleID'),
    language: attr('string'),
    domain: attr('string'),
    alias: attr('string'),
    display_name: attr('string'),
    application_logging: attr('boolean', {
      defaultValue: true
    }),
    backend_type: attr('string', {
      defaultValue: 'be'
    }),
    anyShiftStarted: attr('number', {
      defaultValue: false
    }),
    getTruck: function getTruck() {
      var truckId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTruck');
      if ((0, _utils.isBlank)(truckId)) return null;
      return this.get('store').peekRecord('truck', truckId);
    },
    getTrailer: function getTrailer() {
      var trailerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTrailer');
      if ((0, _utils.isBlank)(trailerId)) return null;
      return this.get('store').peekRecord('trailer', trailerId);
    },
    setSelectedShiftId: function setSelectedShiftId(shiftId) {
      this.set('selectedShiftId', shiftId);

      this._propagateShiftSelection();
    },
    setSelectedTripId: function setSelectedTripId(tripId) {
      this.set('selectedTripId', tripId);

      this._updateSelectedShift();

      this._updateSelectedTripStop();
    },
    setSelectedTripStopId: function setSelectedTripStopId(tripStopId) {
      this.set('selectedTripStopId', tripStopId);

      this._propagateTripStopSelection();
    },
    _propagateShiftSelection: function _propagateShiftSelection() {
      var currentSelectedTripId = this.get('selectedTripId');
      var tripsInSelectedShift = this.get('selectedShift.sortedTrips') || [];
      var tripIdsInSelectedShift = tripsInSelectedShift.map(function (t) {
        return t.get('id');
      });

      if (!tripIdsInSelectedShift.includes(currentSelectedTripId)) {
        var tripId = tripIdsInSelectedShift.get('firstObject');
        this.set('selectedTripId', tripId);

        this._updateSelectedTripStop();
      }
    },
    _propagateTripStopSelection: function _propagateTripStopSelection() {
      var tripId = this.get('selectedTripStop.trip.id');
      this.set('selectedTripId', tripId);

      this._updateSelectedShift();
    },
    _updateSelectedShift: function _updateSelectedShift() {
      var shiftId = this.get('selectedTrip.shift.id');

      if (shiftId) {
        this.set('selectedShiftId', shiftId);
      }
    },
    _updateSelectedTripStop: function _updateSelectedTripStop() {
      var currentSelectedTripStopId = this.get('selectedTripStopId');
      var tripStopsInSelectedTrip = this.get('selectedTrip.sortedDisplayableTripStops') || [];
      var tripStopIdsInSelectedTrip = tripStopsInSelectedTrip.map(function (t) {
        return t.get('id');
      });

      if (!tripStopIdsInSelectedTrip.includes(currentSelectedTripStopId)) {
        var tripStopId = tripStopIdsInSelectedTrip.get('firstObject');
        this.set('selectedTripStopId', tripStopId);
      }
    },
    selectedShift: (0, _object.computed)('selectedShiftId', function () {
      if ((0, _utils.isEmpty)(this.get('selectedShiftId'))) return null;
      return this.get('store').peekRecord('shift', this.get('selectedShiftId'));
    }),
    selectedTrip: (0, _object.computed)('selectedTripId', 'selectedShift.tripsLoaded', function () {
      if ((0, _utils.isEmpty)(this.get('selectedTripId'))) return null;
      return this.get('store').peekRecord('trip', this.get('selectedTripId'));
    }),
    selectedTripStop: (0, _object.computed)('selectedTripStopId', function () {
      if ((0, _utils.isEmpty)(this.get('selectedTripStopId'))) return null;
      return this.get('store').peekRecord('trip-stop', this.get('selectedTripStopId'));
    }),
    apiPath: (0, _object.computed)('useBeBackend', function () {
      if (this.get('useBeBackend')) {
        return _environment.default.api;
      } else {
        return 'request';
      }
    }),
    useBxBackend: (0, _computed.equal)('backend_type', 'bx'),
    useBeBackend: (0, _computed.not)('useBxBackend'),
    internetConnection: (0, _service.inject)(),
    backendConnection: (0, _service.inject)(),
    online: (0, _object.computed)('internetConnection.currentState', 'backendConnection.isAvailable', function () {
      return this.get('internetConnection.currentState') === 'online' && this.get('backendConnection.isAvailable');
    }),
    offline: (0, _computed.not)('online'),
    deviceOrientation: (0, _object.computed)(function () {
      return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
    }),
    truckSettings: (0, _object.computed)('currentTruck', function () {
      var settingsId = this.truckSettingsId(this.get('currentTruck'));
      var settings;
      settings = this.get('store').peekRecord('truck-setting', settingsId);

      if (settings) {
        return settings;
      }

      settings = this.get('store').createRecord('truck-setting', {
        id: settingsId
      });
      return settings;
    }),
    userSettingsService: (0, _service.inject)('user-settings'),
    userSettings: (0, _computed.alias)('userSettingsService.currentSettings')
  });

  _exports.default = _default;
});