define("bottomline-execution/mixins/map-element-data", ["exports", "@ember/object/mixin", "@ember/utils"], function (_exports, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    getTripStopOnMapInfo: function getTripStopOnMapInfo(tripStop, isSelected) {
      var id = tripStop.get('id');

      var tripStopOnMap = this._findOrCreateModel('geographical-map/trip-stop-on-map', id);

      tripStopOnMap.setProperties({
        isSelected: isSelected,
        tripStop: tripStop
      });
      return tripStopOnMap;
    },
    getTankOnMapInfo: function getTankOnMapInfo(tank, tripStop, isSelected) {
      var id = tank.get('id');

      var tankOnMap = this._findOrCreateModel('geographical-map/tank-on-map', id);

      tankOnMap.setProperties({
        isSelected: isSelected,
        tank: tank,
        tripStop: tripStop
      });
      return tankOnMap;
    },
    getOrderOnMapInfo: function getOrderOnMapInfo(order) {
      var id = order.get('id');

      var orderOnMap = this._findOrCreateModel('geographical-map/order-on-map', id);

      orderOnMap.set('order', order);
      return orderOnMap;
    },
    getSiteOnMapInfo: function getSiteOnMapInfo(site) {
      var id = site.get('id');

      var siteOnMap = this._findOrCreateModel('geographical-map/site-on-map', id);

      siteOnMap.set('site', site);
      return siteOnMap;
    },
    getUpdateSiteOnMapInfo: function getUpdateSiteOnMapInfo(tripStop) {
      var id = tripStop.get('id');

      var siteToUpdate = this._findOrCreateModel('geographical-map/update-site-on-map', id);

      siteToUpdate.set('tripStop', tripStop);
      return siteToUpdate;
    },
    getUpdateStorageOnMapInfo: function getUpdateStorageOnMapInfo(tank, tripStop) {
      var id = tank.get('id');

      var storageToUpdate = this._findOrCreateModel('geographical-map/update-storage-on-map', id);

      storageToUpdate.setProperties({
        tank: tank,
        tripStop: tripStop
      });
      return storageToUpdate;
    },
    getTripStopTanksMapInfo: function getTripStopTanksMapInfo(tripStop, isSelected) {
      var _this = this;

      var tanksNotOnSite = tripStop.tanksNotOnSite();
      var tanksOnMap = tanksNotOnSite.map(function (tank) {
        return _this.getTankOnMapInfo(tank, tripStop, isSelected);
      });
      return tanksOnMap;
    },
    getTripStopsOnTheMap: function getTripStopsOnTheMap(tripStops, selectedTripStop) {
      var _this2 = this;

      return tripStops.reduce(function (collection, tripStop) {
        var isSelected = selectedTripStop === tripStop;

        var tripStopOnMap = _this2.getTripStopOnMapInfo(tripStop, isSelected);

        collection.pushObject(tripStopOnMap);

        var tanksOnMap = _this2.getTripStopTanksMapInfo(tripStop, isSelected);

        collection.pushObjects(tanksOnMap);
        return collection;
      }, []);
    },
    getIconSetForInitialZoomMapDefault: function getIconSetForInitialZoomMapDefault() {
      this.set('includeCurrentLocationInZoomGeoMap', false);
      var tripStopsOnTheMap = this.get('tripStopsOnTheMap');
      return this._getTripStopsIconSet(tripStopsOnTheMap);
    },
    getIconSetForInitialZoomMapAddNewStop: function getIconSetForInitialZoomMapAddNewStop(tripStops) {
      var tripStopsOnTheMap = this.getTripStopsOnTheMap(tripStops);
      var displayRegularTripStops = false;
      return this._getTripStopsIconSet(tripStopsOnTheMap, displayRegularTripStops);
    },
    _getTripStopsIconSet: function _getTripStopsIconSet(tripStopsOnTheMap) {
      var displayRegularTripStops = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var unconfirmedUnloadStopsOnTheMap = tripStopsOnTheMap.filter(function (ts) {
        return ts.get('tripStop.isUnload') && !ts.get('tripStop.isConfirmed');
      });

      if ((0, _utils.isPresent)(unconfirmedUnloadStopsOnTheMap)) {
        return unconfirmedUnloadStopsOnTheMap;
      }

      if (displayRegularTripStops) {
        var unloadStopsOnTheMap = tripStopsOnTheMap.filter(function (ts) {
          return ts.get('tripStop.isUnload');
        });

        if ((0, _utils.isPresent)(unloadStopsOnTheMap)) {
          return unloadStopsOnTheMap;
        }

        this.set('includeCurrentLocationInZoomGeoMap', true);
      }

      return tripStopsOnTheMap;
    },
    _findOrCreateModel: function _findOrCreateModel(modelName, modelId) {
      return this.get('store').peekRecord(modelName, modelId) || this.get('store').createRecord(modelName, {
        id: modelId
      });
    }
  });

  _exports.default = _default;
});