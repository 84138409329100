define("bottomline-execution/models/remote-log-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    app: attr('string', {
      defaultValue: 'BE'
    }),
    level: attr('string'),
    message: attr('string'),
    timestamp: attr('number'),
    alias: attr('string'),
    authenticated: attr('boolean'),
    deviceUuid: attr('string'),
    truckId: attr('string'),
    truckDescription: attr('string')
  });

  _exports.default = _default;
});