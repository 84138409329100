define("bottomline-execution/templates/components/input-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sOqVxtDW",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"cssClassNames\"]]],[16,\"min\",[30,0,[\"min\"]]],[16,\"max\",[30,0,[\"max\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[4,[38,1],[\"focus\",[28,[37,2],[[30,0],\"onFocus\"],null]],null]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"numericValue\"]]]],null],[1,\"\\n\"]],[],false,[\"input\",\"on\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});