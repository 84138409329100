define("bottomline-execution/templates/components/editable-t", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TDcLTj3f",
    "block": "[[[41,[30,0,[\"canEdit\"]],[[[1,\"  \"],[10,1],[12],[1,\"\\n    \"],[6,[39,1],null,[[\"warning\",\"actionOnClick\",\"styleGroups\",\"debugLabel\"],[[30,0,[\"isMissing\"]],[28,[37,2],[[30,0],\"toggleEdit\"],null],\"translation-button\",\"toggle_translation_edit\"]],[[\"default\"],[[[[1,\"T\"]],[]]]]],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[[30,0,[\"key\"]]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,5],[[30,0,[\"key\"]]],null]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"bl-components/button\",\"action\",\"has-block\",\"yield\",\"t\"]]",
    "moduleName": "bottomline-execution/templates/components/editable-t.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});