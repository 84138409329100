define("bottomline-execution/utils/url-utils", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function stripTrailingSlash(str) {
    return str.replace(/\/$/, "");
  }

  function extractDomainName(url) {
    if ((0, _utils.isEmpty)(url)) return '';
    var matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    return matches && matches[1];
  }

  function extractUrl(url) {
    if ((0, _utils.isEmpty)(url)) return '';
    var matches = url.match(/^https?:\/\/(.*)/i);
    return matches && matches[1] || '';
  }

  function extractProtocol(url) {
    if ((0, _utils.isEmpty)(url)) return '';
    var matches = url.match(/^https?:\/\//i);
    return matches && matches[0];
  }

  function isIpAddress(address) {
    if ((0, _utils.isEmpty)(address)) return false;
    return (0, _utils.isPresent)(address.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}[:\d]*$/));
  }

  var _default = {
    stripTrailingSlash: stripTrailingSlash,
    extractDomainName: extractDomainName,
    extractUrl: extractUrl,
    extractProtocol: extractProtocol,
    isIpAddress: isIpAddress
  };
  _exports.default = _default;
});