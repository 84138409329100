define("bottomline-execution/models/geographical-map/trip-stop-on-map", ["exports", "ember-data", "@ember/object/computed", "@ember/object"], function (_exports, _emberData, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    tripStop: null,
    description: (0, _object.computed)('tripStop', function () {
      var materialNamesList = this.get('tripStop.plannedMaterials').reduce(function (collector, material) {
        return collector + '<br />' + material.get('MaterialNameShort');
      }, '');
      return this.get('iconTitle') + materialNamesList;
    }),
    iconName: (0, _object.computed)('tripStop', function () {
      if (this.get('tripStop.isLoad') || this.get('tripStop.isCorrectionStop')) return 'depot';
      if (this.get('tripStop.isParking')) return 'parking';
      return 'fillingstation';
    }),
    iconTitle: (0, _computed.alias)('tripStop.sitename'),
    isCompleted: (0, _computed.alias)('tripStop.isConfirmed'),
    isSelected: '',
    latitude: (0, _computed.alias)('tripStop.latitude'),
    longitude: (0, _computed.alias)('tripStop.longitude'),
    zIndex: (0, _object.computed)('tripStop', function () {
      if (this.get('tripStop.isUnload')) return 40;
      if (this.get('tripStop.isLoad')) return 20;
      return 10;
    })
  });

  _exports.default = _default;
});