define("bottomline-execution/components/trip-stop-truck-storage-load", ["exports", "@ember/object/computed", "@ember/component", "@ember/utils", "@ember/object", "@ember/service", "bottomline-execution/mixins/unit-of-calculation", "bottomline-execution/mixins/app-state", "bottomline-execution/utils/find-or-initialize-trip-stop-truck-storage"], function (_exports, _computed, _component, _utils, _object, _service, _unitOfCalculation, _appState, _findOrInitializeTripStopTruckStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, _unitOfCalculation.default, {
    store: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    classNames: 'storage-compartment',
    classNameBindings: ['isHighlightedCompartment', 'isHiddenCompartment'],
    tagName: 'td',
    tripStopStorageChanged: (0, _object.observer)('tripStopStorage', function () {
      this.set('lock', null);
    }),
    isHighlightedCompartment: (0, _computed.alias)('materialMatch'),
    isHiddenCompartment: (0, _computed.not)('showCompartment'),
    showCompartment: (0, _object.computed)('showAllCompartments', 'materialMatch', function () {
      if (this.get('showAllCompartments')) {
        return true;
      } else {
        return this.get('materialMatch');
      }
    }),
    showLock: (0, _computed.and)('tripStopStorageSelected', 'materialMismatch'),
    isLocked: (0, _object.computed)('lock', function () {
      if (this.get('lock') === null) {
        this.set('lock', this.get('defaultLockSetting'));
      }

      return this.get('lock');
    }),
    lock: null,
    defaultLockSetting: (0, _object.computed)('tripStopStorageSelected', function () {
      if (this.get('tripStopStorageSelected')) {
        return this.get('materialMismatch');
      } else {
        return !this.get('tripStopTruckStorage.hasPlannedMaterial');
      }
    }),
    canChangeMaterial: (0, _computed.and)('tripStopStorageSelected', 'materialMismatch'),
    canEnterQuantity: (0, _object.computed)('isLocked', 'tripStopTruckStorage.MaterialID', function () {
      return !this.get('isLocked') && (0, _utils.isPresent)(this.get('tripStopTruckStorage.MaterialID'));
    }),
    canCopyPlannedQuantity: (0, _object.computed)('canEnterQuantity', 'tripStopTruckStorage.hasPlannedMaterial', function () {
      return this.get('canEnterQuantity') && this.get('tripStopTruckStorage.plannedQty') > 0;
    }),
    tripStopStorageSelected: (0, _object.computed)('tripStopStorage', function () {
      return (0, _utils.isPresent)(this.get('tripStopStorage'));
    }),
    materialMatch: (0, _computed.alias)('plannedMaterialSameAsInSelectedTripStopStorage'),
    materialMismatch: (0, _computed.not)('plannedMaterialSameAsInSelectedTripStopStorage'),
    plannedMaterialSameAsInSelectedTripStopStorage: (0, _object.computed)('tripStopStorage.MaterialID', 'tripStopTruckStorage.MaterialID', function () {
      return this.get('tripStopTruckStorage.hasPlannedMaterial') && this.get('tripStopStorage.MaterialID') === this.get('tripStopTruckStorage.MaterialID');
    }),
    trip: (0, _computed.alias)('trip-stop.trip'),
    truckInventories: (0, _computed.alias)('trip.shift.truck_inventories'),
    truckInventory: (0, _object.computed)('truckInventories', function () {
      var truckStorageID = this.get('truckStorage.TruckStorageID');
      return this.get('truckInventories').findBy('truck_storage_id', truckStorageID);
    }),
    confirmedPriorTripStops: (0, _object.computed)(function () {
      var loadStopArrivalDT = moment(this.get('trip').getLoadStop().get('ConfirmedArrivalDT'));
      return this.get('trip.trip_stops').filter(function (t) {
        var tripStopDepartureDT = moment(t.get('ConfirmedDepartureDT'));
        return t.get('isConfirmed') && !t.get('isLoad') && tripStopDepartureDT.isBefore(loadStopArrivalDT);
      });
    }),
    leftOnVehicle: (0, _object.computed)(function () {
      /*
        The load is added on top of the left-on-vehicle:
          'what is on the vehicle at the start of the load'.
         It is calculated as the sum of the confirmed quantities of all trip stops
        that have been confirmed prior to the start of the load stop. This includes
        the start inventory, but also material transfers and delivery stops.
      */
      var confirmedTripStopIds = this.get('confirmedPriorTripStops').mapBy('id');
      var truckStorageID = this.get('truckStorage.TruckStorageID');
      var targetQuantity = this.get('unitOfCalculationQty');
      var sumOfConfirmedQty = this.get('store').peekAll('trip-stop-truck-storage').filter(function (t) {
        var tripStopId = t.get('TripStopID');
        return confirmedTripStopIds.includes(tripStopId) && t.get('TruckStorageID') == truckStorageID;
      }).reduce(function (sum, t) {
        return sum + t.get(targetQuantity);
      }, 0);
      return sumOfConfirmedQty;
    }),
    tripStopTruckStorage: (0, _object.computed)('trip-stop', 'truckStorage', function () {
      var truckStorageID = this.get('truckStorage.TruckStorageID');
      var truckInventory = this.get('truckInventory');
      return (0, _findOrInitializeTripStopTruckStorage.findOrInitializeTripStopTruckStorage)(this.get('trip-stop'), this.get('trip-stop.site.storages'), truckStorageID, truckInventory);
    }),
    totalConfirmedQty: (0, _object.computed)('leftOnVehicle', 'tripStopTruckStorage.confirmedQty', 'tripStopTruckStorage.confirmed15Qty', function () {
      var targetQuantity = this.get('unitOfCalculationQty');
      var tripStopTruckStorage = this.get('tripStopTruckStorage');
      return Number(this.get('leftOnVehicle') || 0) + Number(tripStopTruckStorage.get(targetQuantity));
    }),
    isLoad: (0, _computed.alias)('trip-stop.isLoad'),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_load'),
    showTemperature: (0, _computed.alias)('truckSettings.show_temperature_on_load'),
    canNotBeLoadedAutomatically: (0, _object.computed)('isLoad', 'leftOnVehicle', function () {
      return this.get('isLoad') && this.get('leftOnVehicle') > 0;
    }),
    areActualLitersSet: (0, _object.computed)('totalConfirmedQty', 'leftOnVehicle', function () {
      var totalConfirmedQty = this.get('totalConfirmedQty');
      var leftOnVehicle = this.get('leftOnVehicle');
      if ((0, _utils.isPresent)(totalConfirmedQty) && totalConfirmedQty !== 0 && totalConfirmedQty !== leftOnVehicle) return true;
      return false;
    }),
    isCurrentMaterialNotPlannedMaterial: (0, _object.computed)('truckStorage.material_id', 'tripStopTruckStorage.MaterialID', function () {
      return this.get('truckStorage.material_id') !== this.get('tripStopTruckStorage.MaterialID');
    }),
    showPlannedMaterial: (0, _object.computed)('tripStopTruckStorage.plannedQty', 'isCurrentMaterialNotPlannedMaterial', 'areActualLitersSet', 'canChangeMaterial', 'isLocked', function () {
      if (this.get('tripStopTruckStorage.plannedQty') > 0) return true;
      if (this.get('areActualLitersSet')) return true;
      if (this.get('isCurrentMaterialNotPlannedMaterial')) return true;
      if (this.get('canChangeMaterial') && !this.get('isLocked')) return true;
      return false;
    }),
    selectedTripStopStorage: (0, _computed.alias)('tripStopStorage'),
    getTripStopStorage: function getTripStopStorage() {
      // try to get selected trip-stop-storage
      var tripStopStorage = this.get('selectedTripStopStorage');

      if ((0, _utils.isEmpty)(tripStopStorage)) {
        // no any trip-stop-storage is selected, try to get trip-stop-storage associated with the trip-stop-truck-storage
        tripStopStorage = this.get('tripStopTruckStorage.tripStopStorage');
      }

      if ((0, _utils.isEmpty)(tripStopStorage)) {
        // trip-stop-truck-storage has no associated trip-stop-storage,
        // it rather means that nothing was planned for this trip-stop-truck-storage
        // and the trip-stop-truck-storage is not in database yet, it is an empty entity created on frontend
        var storageId = this.get('tripStopTruckStorage.StorageID');
        var tripStopId = this.get('tripStopTruckStorage.TripStopID');
        tripStopStorage = this.get('store').peekAll('trip-stop-storage').filterBy('StorageID', storageId).filterBy('TripStopID', tripStopId)[0];
      }

      return tripStopStorage;
    },
    _linkedTripStopStorage: function _linkedTripStopStorage() {
      var tripStopStorage = this.getTripStopStorage();

      if ((0, _utils.isEmpty)(tripStopStorage)) {
        return;
      }

      this._linkTripStopStorage(tripStopStorage);

      return tripStopStorage;
    },
    _linkTripStopStorage: function _linkTripStopStorage(tripStopStorage) {
      var unlinkedTripStopStorage = this.get('tripStopTruckStorage').unlinkTripStopStorage();

      if ((0, _utils.isPresent)(unlinkedTripStopStorage)) {
        unlinkedTripStopStorage.updateConfirmedQty();
      }

      this.get('tripStopTruckStorage').linkTripStopStorage(tripStopStorage);
      tripStopStorage.updateConfirmedQty();
    },
    confirmQty: function confirmQty() {
      var qty = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var tripStopStorage = this._linkedTripStopStorage();

      if ((0, _utils.isEmpty)(tripStopStorage)) {
        return;
      }

      if (qty) {
        this.set('tripStopTruckStorage.confirmedQty', qty);
      }

      tripStopStorage.updateConfirmedQty();
    },
    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('lock');
      },
      propagateQuantityChange: function propagateQuantityChange() {
        this.confirmQty();
      },
      copyPlannedQty: function copyPlannedQty(qty) {
        this.confirmQty(qty);
      },
      changePlannedMaterial: function changePlannedMaterial() {
        this._linkTripStopStorage(this.get('selectedTripStopStorage'));
      }
    }
  });

  _exports.default = _default;
});