define("bottomline-execution/utils/grouped-totals", ["exports", "@ember/object", "@ember/array", "@ember/string"], function (_exports, _object, _array, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupedTotalsFor = groupedTotalsFor;

  function groupedTotalsFor(collection, _ref) {
    var groupBy = _ref.groupBy,
        calculateSumFor = _ref.calculateSumFor;
    var groupingAttribute = groupBy;
    var summableAttributes = [].concat(calculateSumFor);
    var groupValues = (0, _array.A)((0, _array.A)(collection).getEach(groupingAttribute)).uniq();
    return groupValues.reduce(function (result, groupValue) {
      var elementsForGroup = collection.filterBy(groupingAttribute, groupValue);
      var currentGroup = new _object.default();
      currentGroup.setProperties({
        groupElements: elementsForGroup,
        groupId: groupValue
      });
      summableAttributes.forEach(function (summableAttribute) {
        var total = elementsForGroup.reduce(function (sum, element) {
          return sum + (parseInt(element.get(summableAttribute)) || 0);
        }, 0);
        currentGroup.set("total".concat((0, _string.capitalize)(summableAttribute)), total);
      });
      return result.concat([currentGroup]);
    }, []);
  }
});