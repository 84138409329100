define("bottomline-execution/components/material-transfer", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/unit-of-calculation", "bottomline-execution/utils/guid-util"], function (_exports, _computed, _component, _object, _service, _appState, _unitOfCalculation, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _component.default.extend(_appState.default, _unitOfCalculation.default, {
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    offline: (0, _computed.alias)('appState.offline'),
    vehicleStorages: (0, _service.inject)(),
    returnToTripIndex: "",
    classNames: ['views', 'material-transfer'],
    tripStop: null,
    trip: (0, _computed.alias)('tripStop.trip'),
    isSourceFromTrailer: false,
    isTargetFromTrailer: false,
    selectedSource: null,
    selectedTarget: null,
    transferAmount: null,
    hasSourceAndTarget: (0, _computed.and)('selectedSource', 'selectedTarget'),
    maxTargetFreeVolume: (0, _object.computed)('selectedSource', 'selectedTarget', 'selectedTarget.truckInventory.currentQty', function () {
      var selectedSource = this.get('selectedSource');
      var selectedTarget = this.get('selectedTarget');

      if (selectedSource && selectedTarget) {
        var targetCurrentQty = selectedTarget.get('truckInventory.currentQty');
        var targetMaxQty = selectedTarget.get('truckInventory.maxQty');
        var freeVolume;

        if (targetCurrentQty >= 0) {
          freeVolume = targetMaxQty - targetCurrentQty;
        } else {
          freeVolume = targetMaxQty;
        }

        if (freeVolume > 0) {
          return freeVolume;
        }
      }

      return 0;
    }),
    maxTargetFreeVolumeInclAllowableExcess: (0, _object.computed)('maxTargetFreeVolume', function () {
      var allowableExcessCoefficient = 1.02;
      return this.get('maxTargetFreeVolume') * allowableExcessCoefficient;
    }),
    hintOfFreeSpace: (0, _object.computed)('transferAmount', 'maxTargetFreeVolume', function () {
      var maxTargetFreeVolume = this.get('maxTargetFreeVolume');
      var transferAmount = this.get('transferAmount');
      return maxTargetFreeVolume - transferAmount;
    }),
    hintOfSourceAvailableQty: (0, _object.computed)('selectedSource.truckInventory.currentQty', 'transferAmount', function () {
      return this.get('selectedSource.truckInventory.currentQty') - this.get('transferAmount');
    }),
    isValidTransferAmount: (0, _object.computed)('transferAmount', 'maxTargetFreeVolumeInclAllowableExcess', function () {
      var maxTargetFreeVolumeInclAllowableExcess = parseInt(this.get('maxTargetFreeVolumeInclAllowableExcess'));
      var transferAmount = parseInt(this.get('transferAmount'));
      var sourceCurrentQty = parseInt(this.get('selectedSource.truckInventory.currentQty'));
      return transferAmount > 0 && transferAmount <= sourceCurrentQty && transferAmount <= maxTargetFreeVolumeInclAllowableExcess;
    }),
    truckCompartments: (0, _object.computed)('tripStop', function () {
      return this.get('vehicleStorages').truckStorages();
    }),
    trailerCompartments: (0, _object.computed)('tripStop', function () {
      return this.get('vehicleStorages').trailerStorages();
    }),
    hasTruckAndTrailerCompartments: (0, _computed.and)('truckCompartments.length', 'trailerCompartments.length'),
    hasTruckStorages: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),
    hasTrailerStorages: (0, _object.computed)('appState.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._prepareNewMaterialTransferStop();
    },
    willDestroyElement: function willDestroyElement() {
      var tripStop = this.get('tripStop');

      if (tripStop.get('isNew')) {
        tripStop.unloadRecord();
      }
    },
    tripStopTruckStorageByTruckStorage: function tripStopTruckStorageByTruckStorage(truckStorage) {
      var truckInventory = truckStorage.get('truckInventory');
      var newTripStop = this.get('tripStop');
      var trip = newTripStop.get('trip');
      return newTripStop.get('trip_stop_truck_storages').createRecord({
        id: (0, _guidUtil.guidUtil)(),
        TripID: trip.get('id'),
        ActivityCode: newTripStop.get('ActivityCode'),
        trip: trip,
        TruckID: truckInventory.get('truck_id'),
        MaterialID: truckInventory.get('material_id'),
        confirmedQty: 0,
        confirmed15Qty: 0,
        TruckStorageID: truckInventory.get('truck_storage_id'),
        plannedQty: 0
      });
    },
    tripStopStorageByTruckStorage: function tripStopStorageByTruckStorage(truckStorage) {
      var truckInventory = truckStorage.get('truckInventory');
      var newTripStop = this.get('tripStop');
      var trip = newTripStop.get('trip');
      return newTripStop.get('trip_stop_storages').createRecord({
        id: (0, _guidUtil.guidUtil)(),
        TripID: trip.get('id'),
        trip: trip,
        TripStopID: newTripStop.get('id'),
        MaterialID: truckInventory.get('material_id'),
        confirmedQty: 0,
        confirmed15Qty: 0,
        plannedQty: 0
      });
    },
    _createTripStopStorages: function _createTripStopStorages(source, destination) {
      var targetQuantity = this.get('unitOfCalculationQty');
      var sourceTripStopStorage = this.tripStopStorageByTruckStorage(source);
      var destinationTripStopStorage = this.tripStopStorageByTruckStorage(destination);
      var quantityInDestination = destination.get('truckInventory.currentQty');

      if (source.get('truckInventory.material_id') != destination.get('truckInventory.material_id')) {
        sourceTripStopStorage.set(targetQuantity, quantityInDestination);
        destinationTripStopStorage.set(targetQuantity, -1 * quantityInDestination);
      }
    },
    _createTripStopTruckStorages: function _createTripStopTruckStorages(source, destination, transferAmount) {
      var _destinationTripStopT;

      var targetQuantity = this.get('unitOfCalculationQty');
      var sourceTripStopTruckStorage = this.tripStopTruckStorageByTruckStorage(source);
      var destinationTripStopTruckStorage = this.tripStopTruckStorageByTruckStorage(destination);
      sourceTripStopTruckStorage.set(targetQuantity, -transferAmount);
      destinationTripStopTruckStorage.setProperties((_destinationTripStopT = {}, _defineProperty(_destinationTripStopT, targetQuantity, +transferAmount), _defineProperty(_destinationTripStopT, "MaterialID", sourceTripStopTruckStorage.get('MaterialID')), _destinationTripStopT));
    },
    _updateTruckInventoryMaterial: function _updateTruckInventoryMaterial(source, destination) {
      destination.get('truckInventory').set('material_id', source.get('truckInventory.material_id'));
    },
    setAmount: function setAmount(amount) {
      var maxTargetFreeVolumeInclAllowableExcess = this.get('maxTargetFreeVolumeInclAllowableExcess');
      this.set('transferAmount', parseInt(amount > maxTargetFreeVolumeInclAllowableExcess ? maxTargetFreeVolumeInclAllowableExcess : amount));
    },
    actions: {
      cancel: function cancel() {
        this.get('trip').calculateTruckInventoryPerCompartment();
        this.get('tripStop').unloadRecord();
        this.get('returnToTripIndex')();
      },
      selectSource: function selectSource(truckStorage) {
        this.send('clearSelectedTarget');
        this.set('selectedSource', truckStorage);
        this.set('transferAmount', null);
      },
      selectTarget: function selectTarget(truckStorage) {
        var selectedSource = this.get('selectedSource');
        var selectedTarget = this.get('selectedTarget');

        if (selectedSource === truckStorage) {
          return this.get('toast').error('Select different compartments');
        }

        this.set('selectedTarget', selectedTarget !== truckStorage ? truckStorage : null);
      },
      clearSelectedSource: function clearSelectedSource() {
        this.set('selectedSource', null);
        this.set('selectedTarget', null);
        this.set('transferAmount', null);
      },
      clearSelectedTarget: function clearSelectedTarget() {
        this.set('selectedTarget', null);
        this.set('transferAmount', null);
      },
      saveMaterialTransition: function saveMaterialTransition() {
        var _this = this;

        var _this$getProperties = this.getProperties('selectedSource', 'selectedTarget'),
            selectedSource = _this$getProperties.selectedSource,
            selectedTarget = _this$getProperties.selectedTarget;

        var transferAmount = Number(this.get('transferAmount'));
        var isValidTransferAmount = this.get('isValidTransferAmount');

        if (isValidTransferAmount && selectedSource && selectedTarget) {
          var tripStop = this.get('tripStop');

          this._createTripStopStorages(selectedSource, selectedTarget);

          this._createTripStopTruckStorages(selectedSource, selectedTarget, transferAmount);

          this._updateTruckInventoryMaterial(selectedSource, selectedTarget);

          tripStop.start();
          tripStop.get('trip.trip_stops').pushObject(tripStop);
          this.get('f7').showPreloader();
          tripStop.save().then(function () {
            return tripStop.confirm().then(function () {
              _this.get('toast').info(_this.get('i18n').t('material_transfer_success'));
            });
          }).catch(function (error) {
            console.error(error);

            _this.get('toast').error(_this.get('i18n').t('material_transfer_error'));
          }).finally(function () {
            _this.get('f7').hidePreloader();

            _this.get('returnToTripIndex')();
          });
        }
      },
      setAmountCompletely: function setAmountCompletely(amount, source) {
        if (this.get('selectedSource') === source) {
          this.setAmount(amount);
        }
      },
      updateTransferAmount: function updateTransferAmount(amount) {
        if (amount) {
          this.setAmount(amount);
        }
      }
    },
    _prepareNewMaterialTransferStop: function _prepareNewMaterialTransferStop() {
      var startedTrip = this.get('startedTrip');
      var nextSequenceNb = startedTrip.getMaxTripStopSequenceNb() + 1;
      var newTripStop = this.get('store').createRecord('trip-stop', {
        TripID: startedTrip.get('id'),
        trip: startedTrip,
        SequenceNb: nextSequenceNb,
        ActivityCode: 'M',
        TruckID: startedTrip.get('TruckID'),
        ResourceID: startedTrip.get('ResourceID'),
        plannedQty: 0,
        state: 'finished',
        ConfirmedSequenceNb: nextSequenceNb,
        ConfirmedArrivalDT: new Date(),
        ConfirmedDepartureDT: new Date()
      });
      this.set('tripStop', newTripStop);
    }
  });

  _exports.default = _default;
});