define("bottomline-execution/templates/trip-stops/scan", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "O83zCHFi",
    "block": "[[[10,0],[14,0,\"views\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"view\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pages\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"page\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n              \"],[10,1],[12],[1,[28,[35,0],null,[[\"key\"],[\"scan_title\"]]]],[13],[1,\"\\n              \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"cancel\"],null],\"cancel\"]]]],[1,\"\\n              \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"scan\"],null],\"scan_retry\"]]]],[1,\"\\n\"],[41,[30,0,[\"previewImage\"]],[[[1,\"                \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"confirm\"],null],\"confirm\"]]]],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n                \"],[10,0],[14,1,\"scan-preview\"],[14,0,\"scan-preview\"],[12],[1,\"\\n\"],[41,[30,0,[\"previewImage\"]],[[[1,\"                    \"],[10,\"img\"],[15,\"src\",[29,[\"data:image/jpeg;base64,\",[30,0,[\"previewImage\"]]]]],[14,5,\"width: 100%\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"bl-components/button\",\"action\",\"if\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/scan.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});