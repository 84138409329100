define("bottomline-execution/templates/components/truck-inventory", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AePBY5EU",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n\\n\"],[41,[30,0,[\"hasTruckStorages\"]],[[[41,[30,0,[\"showVehicleHeaders\"]],[[[1,\"      \"],[10,\"h4\"],[12],[1,[28,[35,1],null,[[\"key\"],[\"current_truck_inventory\"]]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"table\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedTruckInventory\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,4],null,[[\"truckInventory\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasTrailerStorages\"]],[[[41,[30,0,[\"showVehicleHeaders\"]],[[[1,\"      \"],[10,\"h4\"],[12],[1,[28,[35,1],null,[[\"key\"],[\"current_trailer_inventory\"]]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"table\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedTrailerInventory\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,4],null,[[\"truckInventory\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"truckInventory\",\"truckInventory\"],false,[\"if\",\"editable-t\",\"each\",\"-track-array\",\"truck-inventory/item\"]]",
    "moduleName": "bottomline-execution/templates/components/truck-inventory.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});